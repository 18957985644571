<script setup>
import { Plus } from 'lucide-vue-next';

const props = defineProps({
  selectedVariant : {
    default : {}
  },
  direction : {
    default : "vertical"
  }
})

const {isWholesaleUser} = await useCustomer()

const {
  showListPrice,
  showListPriceAndCampaignTogether
} = useListing()

const campaignDiscountExists = computed(() => discountRateIsExits(props.selectedVariant, isWholesaleUser.value))
const priceWillCalculate = computed(() => props.selectedVariant?.primaryStockData[isWholesaleUser.value ? 'wholesalePrice' : 'price'])
const priceDiffPercent = computed(() => {

  // list price yok ise 0 döndür
  if (!props.selectedVariant?.primaryStockData[isWholesaleUser.value ? 'wholesaleListPrice' : 'listPrice']) {
    return 0
  }

  return customRound(relDiff(props.selectedVariant?.primaryStockData[isWholesaleUser.value ? 'wholesaleListPrice' : 'listPrice'], priceWillCalculate.value))
})
const discountData = computed(() => props.selectedVariant.primaryStockData?.discount)
const customTitle = computed(() => props.selectedVariant.primaryStockData?.discount?.config?.customTitle)
const discountDescription = computed(() => customTitle.value ? customTitle.value : props.selectedVariant.primaryStockData?.discount?.description)
const discountExpiredAt = computed(() => props.selectedVariant.primaryStockData?.discount?.expiredAt)
const campaignDiscountRate = computed(() => discountRate(props.selectedVariant, isWholesaleUser.value))
const visibleType = computed(() => props.selectedVariant.primaryStockData?.discount?.config?.visibleType)

const discountSymbol = computed(() => {
  if (unref(discountData)?.config?.type === 'value') {
    return getCurrencySymbol(unref(discountData)?.config?.currency)
  } else {
    return "%"
  }
})

const listPriceExists = computed(() => props.selectedVariant?.primaryStockData?.listPrice > 0)
const inStock = computed(() => props.selectedVariant[isWholesaleUser.value ? 'inStockForB2B' : 'inStock'])
const directionClass = computed(() => props.direction === 'vertical' ? 'flex-col' : 'flex-row')

</script>

<template>
  <div class="discount-label-wrapper" :class="directionClass" v-if="(priceDiffPercent) || campaignDiscountExists && inStock">
    <!-- Senaryo 1 - Kampanya var ise kampanya, yok ise üstü çizili fiyat indirim oranı gözüksün -->
    <template v-if="showListPriceAndCampaignTogether === 0 || !showListPriceAndCampaignTogether || !showListPrice">
      <!-- Kampanya indirim oranı var ise -->
      <div class="discount-percent-label" v-if="campaignDiscountExists">
        <template v-if="discountSymbol === '%'">
          <template v-if="visibleType === 0 || !visibleType">
            {{discountSymbol}}{{ campaignDiscountRate }}
          </template>
          <template v-else>{{discountDescription}}</template>
        </template>
        <template v-else>
          <template v-if="visibleType === 0 || !visibleType">
            {{ campaignDiscountRate }} {{discountSymbol}}
          </template>
          <template v-else>{{discountDescription}}</template>
        </template>
      </div>

      <!-- Kampanya indirim oranı yok ve liste fiyatı farkı var ise ise -->
      <div class="discount-percent-label" v-else-if="listPriceExists && priceDiffPercent && showListPrice">
        %{{priceDiffPercent}}
      </div>
    </template>

    <!-- Senaryo 2 - Üstü çizili fiyat kampanya ile birlikte gözüksün -->
    <template v-else-if="showListPrice && showListPriceAndCampaignTogether === 1">
      <div>
        <div class="discount-percent-label" v-if="listPriceExists && priceDiffPercent">
          %{{priceDiffPercent}}
        </div>
      </div>
      <div>
        <div class="discount-percent-label" v-if="campaignDiscountExists">
          <Plus class="size-3 inline-block mr-1" />
          <template v-if="discountSymbol === '%'">
            <template v-if="visibleType === 0 || !visibleType">{{discountSymbol}}{{ campaignDiscountRate }}</template>
            <template v-else>{{discountDescription}}</template>
          </template>
          <template v-else>
            <template v-if="visibleType === 0 || !visibleType">{{discountDescription}}</template>
            <template v-else>{{ campaignDiscountRate }} {{discountSymbol}}</template>
          </template>
        </div>
      </div>
    </template>

    <!-- Senaryo 3 - Üstü çizili fiyat ve kampanya toplamı/ortalaması gözüksün -->
    <template v-else-if="showListPrice && showListPriceAndCampaignTogether === 2">
      <div class="discount-percent-label" v-if="listPriceExists && priceDiffPercent">
        %{{campaignDiscountExists ? customRound(relDiff(props.selectedVariant?.primaryStockData?.wholesaleListPrice, props.selectedVariant?.primaryStockData?.discountPrice)) : priceDiffPercent}}
      </div>
    </template>
  </div>
</template>