<template>
  <nav class="hidden sm:flex" aria-label="Breadcrumb">
    <ol role="list" class="flex flex-wrap items-center font-medium">
      <li v-if="withHome">
        <div class="flex items-center">
          <NuxtLinkLocale href="/" class="text-sm text-gray-500 hover:text-secondary">
            {{ $t('app.home') }}
          </NuxtLinkLocale>
        </div>
      </li>

      <li v-for="(page,idx) in breadcrumbs" :key="page.label">
        <div class="flex items-center">
          <LucideChevronRight class="flex-shrink-0 h-4 w-4 text-gray-500" v-if="(!withHome && idx !== 0) || withHome" />

          <NuxtLinkLocale :href="page?.url ? checkAndAddSlash(page.url) : '#'" :class="page.current ? 'text-sm text-primary' : 'text-sm text-gray-500 hover:text-secondary'">
            {{ page.label }}
          </NuxtLinkLocale>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
const props = defineProps(['withHome'])
const route = useRoute()
const breadcrumbs = useState(`breadcrumbList::${route.params.slug}`)
const {t} = useI18n()

const breadcrumbsListElement = [
  { name: t('app.home'), item: '/' }
]

if(typeof breadcrumbs.value !== 'undefined'){
  breadcrumbs.value.forEach((i) => {
    breadcrumbsListElement.push({ name: i.label, item: checkAndAddSlash(i.url) })
  })
}

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: breadcrumbsListElement
  }),
])

</script>