<template>
  <div>

    <FormLabel class="text-primary" :is-required="isRequired" v-if="label">
      {{ label }}

      <template #helper>
        <div v-if="$slots.rightHelperArea">
          <slot name="rightHelperArea"></slot>
        </div>
        <span class="text-xs text-gray-600" v-else-if="labelHelper">{{ labelHelper }}</span>
      </template>
    </FormLabel>

    <div class="relative rounded-md">
      <!-- Prefix -->
      <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          v-if="$slots.prefix || loading"
      >
        <svg class="animate-spin h-4 w-4 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" v-if="loading">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                  stroke-width="3"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <slot name="prefix" v-else></slot>
      </div>

      <template v-if="inputType === 'textarea'">
          <textarea
              :id="id"
              :cols="cols"
              :rows="rows"
              class="input-form"
              v-model="value"
              :disabled="disabled"
              :readonly="readonly"
              :name="name"
              :class="{
                'pl-9': $slots.prefix || loading,
                'pr-9': $slots.suffix,
                'is-error': error,
                'is-success': success
              }, [customClass, sizeClass]"
              :placeholder="placeholder">
          </textarea>
      </template>
      <template v-else-if="inputType === 'tel'">
        <VueTelInput
            v-model="value"
            mode="international"
            :disabled="disabled"
            defaultCountry="tr"
            :dropdownOptions="{
              showSearchBox : true,
              showFlags : true,
              showDialCodeInList : true,
              searchBoxPlaceholder : $t('ui-form.input.search-country'),
            }"
            :inputOptions="{
              autocomplete : 'off',
              placeholder : $t('ui-form.input.phone-placeholder'),
              readonly : readonly
            }"
            :preferredCountries="['tr', 'us', 'uk','fr','de','fi','au','mx','br','sw','es','is']"
        />
      </template>
      <template v-else>
        <input
            :id="id"
            :type="inputType"
            class="input-form text-base"
            v-model="value"
            :disabled="disabled"
            :class="{
                  'pl-9': $slots.prefix || loading,
                  'pr-9': $slots.suffix,
                  'is-error': error,
                  'is-success': success
                }, [customClass, sizeClass]"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :readonly="readonly"
            v-bind="$attrs"
            :name="name">
      </template>

      <!-- Suffix -->
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center" v-if="$slots.suffix">
        <slot name="suffix"></slot>
      </div>

      <div
          class="absolute inset-y-0 right-0 flex items-center pointer-events-none"
          :class="$slots.suffix ? 'pr-8' : 'pr-3'"
          v-if="error"
      >
        <ExclamationCircleIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
        />
      </div>

      <div
          class="absolute inset-y-0 right-0 flex items-center pointer-events-none"
          :class="$slots.suffix ? 'pr-8' : 'pr-3'"
          v-if="success"
      >
        <CheckCircleIcon class="h-5 w-5 text-green-500" aria-hidden="true"/>
      </div>
    </div>

    <p class="mt-2 text-xs text-gray-500" v-if="formHelper">
      {{ formHelper }}
    </p>
    <p class="text-xs font-medium text-red-600" v-if="error">{{ error }}</p>
    <p class="mt-2 text-sm text-green-600" v-if="successText">{{ successText }}</p>

  </div>
</template>
<script setup>
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from "@heroicons/vue/24/solid";

const props = defineProps({
  modelValue: [String, Number],
  inputType: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  formHelper: {
    type: String,
    default: "",
  },
  labelHelper: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: null,
  },
  success: {
    type: Boolean,
    default: false,
    validator: function (value) {
      // The value must match one of these strings
      return [true, false].indexOf(value) !== -1;
    },
  },
  successText: {
    type: String,
    default: "",
  },
  password: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: null
  },
  buttonColor: {
    type: String,
    default: "gray"
  },
  rows: {
    type: Number,
    default: null
  },
  cols: {
    type: Number,
    default: null
  },
  mask: {
    type: [String, Array],
    default: null
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  id: {
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  size: {
    default: ''
  },
  isRounded: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['update:modelValue', 'change']);

let selfValue = ref(props.modelValue);
const value = computed({
  get() {
    return selfValue.value;
  },
  set(val) {
    selfValue.value = val;

    emit('update:modelValue', val)
  }
})

watch(() => props.modelValue, (newValue) => {
  value.value = newValue;
}, {deep: true});

const sizeClass = computed(() => {
  if (props.size === 'sm') {
    return 'is-small'
  } else {
    return ''
  }
})

</script>