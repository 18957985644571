<script setup>
const {product} = await useProduct()
const {status} = await useAuth()
const {authModalIsVisible} = await useCustomer()

const {data: questions, status : pendingStatus} = useLazyFetch(() => `/api/productQuestions?productId=${product?.value?.productId}`);

const openModal = ref(false)

const setOpenModal = (val) => {
  openModal.value=val
}

const changeModal = async () => {
  if (status.value === 'authenticated') {
    setOpenModal(true);
  } else {
    authModalIsVisible.value = true
  }
}

</script>

<template>
  <div class="text-primary space-y-4">
    <div class="justify-end w-full text-right">
      <FormButton
        color="primary"
        size="md"
        class="sm:w-fit w-full"
        @click="changeModal"
      >
        {{ $t('comps.question.button') }}
      </FormButton>
    </div>
    <div>
      <div v-if="questions?.results?.length > 0">
        <div v-for="result in questions.results" class="flex flex-col" :key="result">
          <div class="flex text-center py-4 justify-between">
            <span class="font-bold text-left">
              <span class="text-secondary">{{ $t('comps.question.question') }} : </span>
              {{ result.question }}
            </span>
            <span class="text-xs text-lightgray-400">
              {{ result.customerName }} - {{ result.questionDate }}
            </span>
          </div>
          <div class="flex text-center justify-between p-4 bg-gray-100 rounded-lg">
            <span class="text-left">
              <span class="text-secondary">{{ $t('comps.question.answer') }}:</span> {{ result.answer }}
            </span>
            <span class="text-xs text-lightgray-400">
              {{ result.answerDate }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>{{ $t('comps.question.no-question') }}</div>
    </div>

    <FeaturesQuestionModal
      v-if="openModal"
      :openModal="openModal"
      :setOpenModal="setOpenModal"
      :product="product"
    />

  </div>
</template>


