import {collect} from "collect.js";
import {getLocaleString} from "~/composables/useHelper";

const cartQuantity = ref(1)

export default async () => {

    const {locale} = useI18n()

    const storeConfig = useStoreConfig()
    const {baseConfig, productConfig, currency} = storeToRefs(storeConfig)

    const route = useRoute()

    const {
        selectedWarehouse
    } = useListing()

    // Fetch data
    const product = useState('productData', () => {})
    const cartAttributes = useState('cartAttributes', () => Object.assign({}))
    const selectedVariant = useState('productSelectedVariant', () => {})
    const selectedSize = useState('productSelectedSize', () => null)
    const sizeChartVisible = useState('sizeChartVisible', () => false)

    const breadcrumbs = useState(`breadcrumbList::${route.params.slug}`, () => [])
    const attributeTranslations = useState('productDetailAttributeTranslations', () => {})
    const productMeta = useState(`productMeta::${route.params.slug}`, () => {})

    const showcaseDescription = useState('showcaseDescription', () => getLocaleString(unref(baseConfig)?.showcase_description) ? getLocaleString(unref(baseConfig)?.showcase_description) : 'Bu ürün şu anda satılamamaktadır')
    const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

    const lowestInstallmentPrice = useState(`productLowestInstallmentPrice::${route.params.slug}`)
    const installments = useState(`productInstallments::${route.params.slug}`, () => [])

    const stockCollection = collect(selectedVariant.value?.primaryStockData);
    const salePrice = computed(() => stockCollection.pluck('price').first())
    const listPrice = computed(() => stockCollection.pluck('price').first())
    const wholesalePrice = computed(() => stockCollection.pluck('wholesalePrice').first())
    const customerPrice = computed(() => stockCollection.pluck('customerPrice').first())
    const discountPrice = computed(() => stockCollection.pluck('discount.discountPrice').first())

    const finalPrice = computed(() => selectedVariant.value?.primaryStockData?.discount.discountPrice ? selectedVariant.value?.primaryStockData?.discount.discountPrice : selectedVariant.value?.primaryStockData?.price)
    const priceCurrency = computed(() => selectedVariant.value?.primaryStockData?.currency)

    const criticalQtyLimit = computed(() => productConfig.value?.critical_qty_limit)
    const criticalQtyText = computed(() => getLocaleString(productConfig.value?.critical_qty_text))
    const weights = computed(() => {
        if (selectedVariant.value?.primaryStockData?.weights.length < 1) {
            return []
        }

        return collect(selectedVariant.value?.primaryStockData?.weights).sortBy('weight').toArray()
    })

    const discountPriceExists = computed(() => unref(selectedVariant)?.primaryStockData?.price !== unref(selectedVariant)?.primaryStockData?.discountPrice && unref(selectedVariant)?.primaryStockData?.discountPrice > 0)

    const isModelExists = computed(() => unref(selectedVariant)?.model?.length > 0)

    const productSlideBreakpoints = ref({
        1024: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
    })

    // const productDataForJsonLD = computed(() => {
    //     return {
    //         name: unref(selectedVariant)?.title,
    //         sku: unref(selectedVariant)['barcodeId'], // TODO : dynamic
    //         image: [],
    //         category: unref(product)?.categories?.length ? getLocaleString(unref(product)?.categories[0][unref(product)?.categories.length -1]?.categoryName) : null,
    //         offer : {
    //             price : finalPrice.value,
    //             priceCurrency : unref(priceCurrency)
    //         },
    //         brand : {
    //             '@type' : 'Brand',
    //             name : unref(product)?.brandName
    //         },
    //         // aggregateRating: {
    //         //   ratingValue: 88,
    //         //   bestRating: 100,
    //         //   ratingCount: 20,
    //         // },
    //         // review: [
    //         //     {
    //         //         name: 'Awesome product!',
    //         //         author: {
    //         //             name: 'Harlan Wilton',
    //         //         },
    //         //         reviewRating: {
    //         //             ratingValue: 5,
    //         //         },
    //         //     },
    //         // ],
    //     }
    // })

    const selectedColorGroup = computed(() => {
        return collect(unref(product).variants).where('colorCode', '=', selectedVariant.value.colorCode).toArray()
    })

    const productCurrency = computed(() => {
        return selectedVariant.value?.primaryStockData?.currency
    })

    const setSelectedVariant = (variant) => {
        const selectedVariantVariable = collect(unref(product).variants)
            .where('colorCode', '=', variant.colorCode)
            .filter((i) => i.size.value === selectedSize.value);

        if (selectedVariantVariable.count()) {
            selectedVariant.value = unref(selectedVariantVariable.first())
        } else {
            selectedVariant.value = variant;
        }

        // URL güncelliyoruz
        if (typeof history !== 'undefined') {
            history.replaceState({}, "", selectedVariant.value.url)
        }

        refreshProductSharableLink()

        // Sepet adedini default değere alıyoruz
        cartQuantity.value = 1;
    }

    const setSelectedSize = (size) => {
        selectedSize.value = size

        // Sepet adedini default değere alıyoruz
        cartQuantity.value = 1;
    }

    const setProduct = async (product) => {
        product.value = product
        selectedVariant.value = product?.selectedVariant
    }

    const getInstallments = async (price) => {

        const priceValue = price ? price : finalPrice.value;

        if (typeof priceValue === 'undefined' || !priceValue) {
            return;
        }

        const data = await $fetch(`/api/installments?price=${priceValue}`);
        lowestInstallmentPrice.value = collect(data).min("lowestInstallmentPrice");
        installments.value = data;
    };

    const getPriceListPrice = (groupCode) => {
        if(typeof selectedVariant.value?.primaryStockData?.priceGroups === 'undefined' || selectedVariant.value?.primaryStockData?.priceGroups?.length === 0 || !groupCode) return null;
        return collect(selectedVariant.value?.primaryStockData?.priceGroups).where('group_code', groupCode).pluck('discounted_wholesale_price').first()
    }

    const productLink = useState('shareProductLink')
    const refreshProductSharableLink = () => {
        if (typeof window !== 'undefined') {
            productLink.value = window.location.href
        }
    }


    const showRelatedProducts = computed(() => !productConfig.value?.is_hide_related_products)

    return {
        breadcrumbs,
        product,
        attributeTranslations,
        productMeta,
        selectedVariant,
        lowestInstallmentPrice,
        installments,
        cartQuantity,
        showcaseDescription,
        isShowcaseMode,
        salePrice,
        listPrice,
        wholesalePrice,
        discountPrice,
        customerPrice,
        finalPrice,
        sizeChartVisible,
        criticalQtyLimit,
        criticalQtyText,
        discountPriceExists,
        setSelectedVariant,
        getInstallments,
        setProduct,
        setSelectedSize,
        getPriceListPrice,
        selectedColorGroup,
        refreshProductSharableLink,
        productSlideBreakpoints,
        weights,
        productCurrency,
        isModelExists,
        cartAttributes,
        showRelatedProducts,
        priceCurrency
    }
}