<template>
  <div class="rating">
    <ul class="list">
      <template v-if="editable">
        <li
          :key="star"
          v-for="star in maxStars"
          :class="{ active: star <= stars }"
          @click="rate(star)"
          class="star"
        >
          <StarIcon class="h-5 w-5" />
        </li>
      </template>
      <template v-if="!editable">
        <li
          :key="star"
          v-for="star in maxStars"
          :class="{ active: star <= stars }"
          class="starNotEditable"
        >
          <StarIcon class="h-5 w-5" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { StarIcon } from "@heroicons/vue/24/solid";
const props = defineProps({
  modelValue: {
    type: Number,
    default: NaN,
  },
  grade: {
    type: Number,
    required: true,
  },
  maxStars: {
    type: Number,
    default: 5,
  },
  hasCounter: {
    type: Boolean,
    default: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

let stars = useState(() => props.grade);

const emit = defineEmits(['update:modelValue', 'change']);

let selfValue = ref(props.modelValue);
const value = computed({
  get() {
    return selfValue.value;
  },
  set(val) {
    selfValue.value = val;

    emit('update:modelValue', val)
  }
})

function rate(star) {
  if (typeof star === "number" && star <= props.maxStars && star >= 0)
    value.value=star
    return (stars.value = stars.value === star ? star - 1 : star);
}
</script>

<style scoped lang="scss">
$active-color: #f3d23e;

.rating {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 22px;
  color: #d7dae7;
}
.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
}
.star {
  display: inline-block;
  cursor: pointer;
  &:hover {
    & ~ .star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
}
.starNotEditable {
  display: inline-block;
  cursor: pointer;
}
.active {
  color: $active-color;
}
</style>
