export default () => {
    // Event list : https://www.facebook.com/business/help/402791146561655?id=1205376682832142

    /**
     * Ödeme bilgisi eklenmesi
     * Bir ödeme işlemi sırasında müşterinin ödeme bilgilerinin eklenmesi. Örneğin, bir kişinin ödeme bilgilerini kaydetmek için bir düğmeye tıklaması.
     */
    const fbAddPaymentInfo = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'AddPaymentInfo')
        }
    }

    /**
     * Sepete ekleme
     * Bir ürünün alışveriş sepetine eklenmesi. Örneğin, bir sitede Sepete Ekle düğmesine tıklanması.
     */
    const fbAddToCart = (content_ids, content_name = null, value = null, currency = null, content_type = 'product') => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'AddToCart', {
                content_name,
                content_ids,
                value,
                currency,
                content_type
            })
        }
    }

    /**
     * Dilek listesine ekleme
     * Dilek listesine ürünler eklenmesi. Örneğin, bir sitede Dilek Listesine Ekle düğmesine tıklanması.
     */
    const fbAddToWishlist = (content_name, content_ids, currency, value) => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'AddToWishlist', {
                content_name,
                content_ids,
                currency,
                value
            })
        }
    }

    /**
     * Kaydın tamamlanması
     * Bir müşteri tarafından, işletmenizin sunduğu bir hizmet karşılığında bilgi gönderilmesi. Örneğin, bir e-posta aboneliğine kaydolunması.
     */
    const fbCompleteRegistration = (customerName, customerEmail) => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'CompleteRegistration', {
                content_name : customerName,
                value : customerEmail
            })
        }
    }

    /**
     * Alışveriş başlatılması
     * Bir ödeme işleminin başlangıcı. Örneğin, Alışverişi Tamamlama düğmesine tıklanması.
     */
    const fbInitiateCheckout = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'InitiateCheckout')
        }
    }

    /**
     * Potansiyel müşteri
     * Bir müşterinin daha sonra işletmeniz tarafından kendisiyle iletişime geçilebileceğini kabul ederek bilgilerini göndermesi.
     * Örneğin, bir form gönderilmesi veya denemeye kaydolunması.
     */
    const fbLead = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Lead')
        }
    }

    /**
     * Alışveriş
     * Genellikle bir siparişin veya alışveriş onayının alınması ya da işlem makbuzunun oluşturulması ile birlikte bir alışverişin tamamlanması.
     * Örneğin, Teşekkür veya onay sayfasına ulaşılması.
     */
    const fbPurchase = (amount, currency) => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Purchase',  {value: amount, currency: currency})
        }
    }

    /**
     * Arama yapılması
     * Sitenizde, uygulamanızda veya başka bir varlıkta gerçekleştirilen bir arama. Örneğin, ürün veya seyahat aramaları.
     */
    const fbSearch = (searchQuery) => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Search', {
                search_string : searchQuery
            })
        }
    }

    /**
     * İletişime geçilmesi
     * Müşteri ve işletme arasında telefon, SMS, e-posta, sohbet veya başka bir yol üzerinden kurulan iletişim.
     */
    const fbContact = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Contact')
        }
    }

    /**
     * İçerik görüntülenmesi
     * Önemsediğiniz bir internet sayfasına yapılan bir ziyaret.
     * Örneğin, bir ürün veya yönlendirme sayfası. İçerik görüntüleme, bir kişinin bir internet sayfası URL'sine gittiği bilgisini aktarır ancak kişinin bu internet sayfasında ne yaptığını veya ne gördüğünü tespit edemez.
     */
    const fbViewContent = (content_name, content_category, content_ids, value, currency, content_type = 'product') => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'ViewContent', {
                content_name,
                content_category,
                content_ids,
                content_type,
                value,
                currency,
            })
        }
    }

    return {
        fbAddPaymentInfo,
        fbAddToCart,
        fbAddToWishlist,
        fbCompleteRegistration,
        fbInitiateCheckout,
        fbLead,
        fbPurchase,
        fbSearch,
        fbContact,
        fbViewContent

    }
}