<template>
  <!-- Product Brand -->
  <span class="product-brand" id="productBrand">
    <slot />
  </span>
</template>

<script setup>

</script>

<style scoped>

</style>