<template>
    <div v-if="product?.variantGroups.length >= 1">
        <div class="text-base" v-if="selectedVariant?.colorName">
            {{ attributeTranslations.color }}
            <span class="capitalize">: {{ selectedVariant?.colorName }}</span>
        </div>
        <div class="flex flex-wrap gap-2.5 mt-2" v-if="product?.productType === 'combine' && product?.variantGroups.length > 1">
            <div
                    v-for="color in product?.variantGroups"
                    :key="color?.colorCode"
                    :class="['border-2 overflow-hidden', color?.colorCode === selectedVariant?.colorCode ? 'border-primary' : 'border-transparent']"
                    @click="setSelectedVariant(color);"
            >
                <NuxtImg
                    :src="getImage(color?.thumbnail)"
                    width="50"
                    height="75"
                    :alt="color?.colorCode"
                    v-if="color?.url && color?.url !== 'null'"
                    class=" cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const {
    product,
    selectedVariant,
    attributeTranslations,
    setSelectedVariant
} = await useProduct()

</script>

<style scoped>

</style>