<template>
  <div class="product-features-bar--wrapper">
    <div class="product-features-bar--navigation">
      <template v-for="title in titles">
        <div v-if="title.isShow" @click="filterActiveTitle(title?.id)"
             class="uppercase font-bold cursor-pointer py-3 text-base h-full"
             :class="{
            'text-secondary border-b border-secondary': activeTitle.id === title.id,
            'text-primary': activeTitle.id !== title.id,
          }"
        >
          {{ title.name }}
        </div>
      </template>
    </div>

    <div class="px-10 py-4 sm:py-8 text-base">

      <!-- Ürün Açıklaması -->
      <div v-if="activeTitle.id===1 && activeTitle.isShow" class="text-base text-primary">
        <div v-if="selectedVariant?.description" v-html="selectedVariant.description"></div>
        <p v-else>{{ $t('listing.no-product-description') }}</p>
      </div>

      <!-- Ürün Değerlendirmeleri -->
      <FeaturesReview v-if="activeTitle.id===2 && activeTitle.isShow" />

      <!-- Ürün Soru & Cevapları -->
      <FeaturesQuestion v-if="activeTitle.id===3 && activeTitle.isShow" />

      <!-- İade Açıklaması -->
      <div v-if="activeTitle.id===4 && activeTitle.isShow" class="text-primary" v-html="getLocaleString(productConfig?.refund_description)"></div>

      <!-- Manken Bilgisi -->
      <FeaturesModelInfo :models="selectedVariant.model" v-if="activeTitle.id===5 && activeTitle.isShow" class="text-primary" />

    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const {productConfig} = storeToRefs(storeConfig)

const {showOnlyB2C} = useHelper()
const {t} = useI18n()

const {
  product,
  selectedVariant,
  installments,
  productQuestions,
  isModelExists
} = await useProduct()

const titles = shallowRef([
  {id: 1, name: t('listing.feature-disclosure-titles.product-description'), isShow : true},
  {id: 2, name: t('listing.feature-disclosure-titles.comments'), isShow : showOnlyB2C.value},
  {id: 3, name: t('listing.feature-disclosure-titles.qa'), isShow : showOnlyB2C.value},
  {id: 4, name: t('listing.feature-disclosure-titles.shipping-and-return'), isShow : showOnlyB2C.value && getLocaleString(productConfig.value?.refund_description)},
  {id: 5, name: t('listing.feature-disclosure-titles.model-info'), isShow: isModelExists.value},
])

const activeTitle = ref(titles.value[0]);
const filteredTitle = useState(() => []);

const filterActiveTitle = (titleId) => {
  filteredTitle.value = titles.value.filter((title) => title?.id === titleId);
};

const FeatureTemplate = shallowRef();

watch(filteredTitle, (oldValue, newValue) => {
  activeTitle.value = oldValue[0];
});

</script>

<style>
.product-features-bar--wrapper {
  @apply border border-lightgray rounded-lg sm:mt-16 mt-8;
}

.product-features-bar--navigation {
  @apply flex md:justify-center gap-7 sm:gap-20 border-b border-lightgray whitespace-nowrap overflow-y-auto px-5;
}
</style>