
export default defineNuxtPlugin((nuxtApp) => {
    const storeConfig = useStoreConfig()
    const {googleAnalyticsConfig} = storeToRefs(storeConfig)

    const isCookieAccepted = useState('isCookieAccepted')
    const { gtag, initialize } = useGtag()

    // Google Anayltics aktif ise yüklüyoruz
    if (googleAnalyticsConfig.value.isActive){
        // initialize(googleAnalyticsConfig.value.IdentityId)

        gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
        })
    }

    // Google Ads dönüşüm takibi aktif ise yüklüyoruz
    if (googleAnalyticsConfig.value?.adsConfigId){
        // initialize(googleAnalyticsConfig.value?.adsConfigId)
    }
})