<template>
  <div
    v-if="
      criticalQtyLimit !== 0 &&
      criticalQtyLimit !== null &&
      criticalQtyLimit !== undefined &&
      quantity <= criticalQtyLimit &&
      quantity > 0
    "
  >
    <div class="product-critical-quantity-wrapper">
      <ClockIcon class="w-4 h-4 mr-1" />
      {{ quantityText ? quantityText : $t('listing.critic-qty-alert', [quantity]) }}
    </div>
  </div>
</template>
<script setup>
import { ClockIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  quantity : {
    default : 0,
  }
})

const { criticalQtyLimit, criticalQtyText, selectedVariant } = await useProduct();
const quantityText = computed(() => criticalQtyText.value ? criticalQtyText.value?.replace("$quantity", props.quantity) : criticalQtyText.value);
</script>
