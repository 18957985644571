<script setup>

const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)
const {t} = useI18n()
const maintenanceDescription = computed(() => baseConfig.value?.maintenance_description)

useHead({
  title: t('maintenance-mode.title')
})

</script>

<template>
    <div class="container py-20 text-center flex flex-col items-center justify-center">
      <div class="flex flex-col items-center justify-center text-center py-4 px-4 space-y-10">
        <div>
          <NuxtImg
              src="/images/500.png"
              width="425"
              height="342"
              alt="500"
              class="object-contain"
          />
        </div>
        <div class="font-medium text-primary">
          <p>
            {{ getLocaleString(maintenanceDescription) ? getLocaleString(maintenanceDescription) : $t('maintenance-mode.description')}}
          </p>
        </div>
      </div>
    </div>
</template>

<style scoped>

</style>