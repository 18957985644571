export default () => {

    const route = useRoute()
    const oldUrl = useState('quickModalOldUrl', () => "")
    const showQuickModal = useState('showQuickModal', () => false)
    const quickModalUrl = useState('quickModalUrl')

    const openQuickModal = (url) => {

        oldUrl.value = route.params.slug
        const newUrl = url.replace(/^\/|\/$/g, '')

        if (typeof history !== 'undefined') {
            history.replaceState({}, "", newUrl)
        }

        quickModalUrl.value=newUrl
        showQuickModal.value=true;
    }

    const closeQuickModal = () => {
        showQuickModal.value=false;
        quickModalUrl.value=null

        if (typeof history !== 'undefined' && oldUrl.value) {
            history.replaceState({}, "", oldUrl.value)
        }
    }

    return {
        showQuickModal,
        quickModalUrl,
        openQuickModal,
        closeQuickModal
    };
};
