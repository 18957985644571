<template>
  <div class="w-full sm:mt-16 mt-8" v-if="!relatedProductsLoading && relatedProducts?.totalProducts > 0">
    <SwiperProduct
        :widget-title="widgetTitle"
        show-navigation
        :products="relatedProducts.results"
        :breakpoints="breakpoints"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  widgetTitle: {
    type: String,
  },
  prevEl: {
    type: String,
  },
  nextEl: {
    type: String,
  },
  disabledClass: {
    type: String,
    default:"opacity-75"
  }
});

const {product} = await useProduct()

const breakpoints = {
  '(max-width: 767px)': {
    slides: { perView: 1.7, spacing: 10 },
  },
  '(min-width: 320px and max-width: 767px)': {
    slides: { perView: 3.5, spacing: 16 },
  },
  '(min-width: 768px)': {
    slides: { perView: 5, spacing: 16 },
  },
}

const {data:relatedProducts, pending: relatedProductsLoading} = useLazyAsyncData(
    `relatedProducts::${product.value?.productId}`,
    () => $fetch('/api/product/related', {
      method: 'POST',
      body: {
        productIds: product.value?.productId,
      },
      server : false,
    })
)

</script>