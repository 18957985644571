export default () => {
    const { gtag } = useGtag()
    const {isMobile, isTablet, isDesktop } = useDevice()

    /**
     * TODO
     * Ödeme bilgisi eklenmesi
     * Bir ödeme işlemi sırasında müşterinin ödeme bilgilerinin eklenmesi. Örneğin, bir kişinin ödeme bilgilerini kaydetmek için bir düğmeye tıklaması.
     */
    const gAddPaymentInfo = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'AddPaymentInfo')
        }
    }

    /**
     * Sepete ekleme
     * Bir ürünün alışveriş sepetine eklenmesi. Örneğin, bir sitede Sepete Ekle düğmesine tıklanması.
     */
    const gAddToCart = (content_id, content_name = "", value = 0, currency = 'TRY') => {
        useTrackEvent('add_to_cart', {
            value,
            currency,
            items : [
                {
                    item_id : content_id,
                    item_name : content_name
                }
            ]
        })
    }

    /**
     * Dilek listesine ekleme
     * Dilek listesine ürünler eklenmesi. Örneğin, bir sitede Dilek Listesine Ekle düğmesine tıklanması.
     */
    const gAddToWishlist = (currency, price, item_id, item_name) => {
        useTrackEvent('add_to_wishlist', {
            currency,
            value : price,
            items : [
                {
                    item_id,
                    item_name
                }
            ]
        })
    }

    /**
     * Kaydın tamamlanması
     * Bir müşteri tarafından, işletmenizin sunduğu bir hizmet karşılığında bilgi gönderilmesi. Örneğin, bir e-posta aboneliğine kaydolunması.
     */
    const gCompleteRegistration = () => {
        useTrackEvent('sign_up', {
            method : isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop')
        })
    }

    /**
     * Alışveriş başlatılması
     * Bir ödeme işleminin başlangıcı. Örneğin, Alışverişi Tamamlama düğmesine tıklanması.
     */
    const gInitiateCheckout = (currency, value, items, coupon = null) => {
        useTrackEvent('begin_checkout', {
            value,
            currency,
            coupon,
            items
        })
    }

    /**
     * TODO
     * Potansiyel müşteri
     * Bir müşterinin daha sonra işletmeniz tarafından kendisiyle iletişime geçilebileceğini kabul ederek bilgilerini göndermesi.
     * Örneğin, bir form gönderilmesi veya denemeye kaydolunması.
     */
    const gLead = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Lead')
        }
    }

    /**
     * Alışveriş
     * Genellikle bir siparişin veya alışveriş onayının alınması ya da işlem makbuzunun oluşturulması ile birlikte bir alışverişin tamamlanması.
     * Örneğin, Teşekkür veya onay sayfasına ulaşılması.
     */
    const gPurchase = (value, currency, transaction_id, items) => {

        console.log('purchase tetiklendi', {
            value,
            currency,
            transaction_id,
            items
        })

        useTrackEvent('purchase', {
            value,
            currency,
            transaction_id,
            items
        })
    }

    /**
     * Arama yapılması
     * Sitenizde, uygulamanızda veya başka bir varlıkta gerçekleştirilen bir arama. Örneğin, ürün veya seyahat aramaları.
     */
    const gSearch = (searchTerm) => {
        useTrackEvent('search', {
            search_term : searchTerm
        })
    }

    /**
     * TODO
     * İletişime geçilmesi
     * Müşteri ve işletme arasında telefon, SMS, e-posta, sohbet veya başka bir yol üzerinden kurulan iletişim.
     */
    const gContact = () => {
        if (typeof window !== 'undefined' && window.hasOwnProperty('fbq')) {
            window.fbq('track', 'Contact')
        }
    }

    /**
     * İçerik görüntülenmesi
     * Önemsediğiniz bir internet sayfasına yapılan bir ziyaret.
     * Örneğin, bir ürün veya yönlendirme sayfası. İçerik görüntüleme, bir kişinin bir internet sayfası URL'sine gittiği bilgisini aktarır ancak kişinin bu internet sayfasında ne yaptığını veya ne gördüğünü tespit edemez.
     */
    const gViewContent = (item_id, item_name, value, currency) => {
        useTrackEvent('view_item', {
            currency,
            value,
            items : [
                {
                    item_id,
                    item_name
                }
            ]
        })
    }

    const gConversation = (payload, url) => {
        if (typeof gtag != 'undefined') {

            let callback = function () {
                if (typeof(url) != 'undefined') {
                    window.location = url;
                }
            };

            gtag('event', 'conversion', {
                ...payload,
                'event_callback': callback
            });

            console.log('conversation tetiklendi', payload, typeof gtag)
        } else {
            console.log('conversation tetiklenemedi!', payload)
        }

        return false;
    }

    const gViewItemList = (item_list_id, item_list_name, items) => {
        useTrackEvent('view_item_list', {
            item_list_id,
            item_list_name,
            items
        })
    }

    const gViewCart = (value, currency, items) => {
        useTrackEvent('view_cart', {
            value,
            currency,
            items
        })
    }

    return {
        gAddPaymentInfo,
        gAddToCart,
        gAddToWishlist,
        gCompleteRegistration,
        gInitiateCheckout,
        gLead,
        gPurchase,
        gSearch,
        gContact,
        gViewContent,
        gConversation,
        gViewItemList,
        gViewCart
    }
}