export const getLocaleString = (payload, withRootSlash = false) => {
    const {locale} = useI18n()
    let rootSlash = "";

    if (typeof payload === 'undefined' || Array.isArray(payload)) {
        return ''
    }

    if (withRootSlash) {
        rootSlash = '/'
    }

    try {
        if (withRootSlash) {
            return payload[locale.value]?.search(/^http[s]?:\/\//) === 0 ? payload[locale.value].replace(/^\/|\/$/g, '') : rootSlash+payload[locale.value].replace(/^\/|\/$/g, '');
        } else {
            return payload[locale.value];
        }
    } catch (e) {
        if (Array.isArray(payload) && payload.length === 0) {
            return rootSlash+"";
        } else if (typeof payload === 'object' && payload && payload.tr) {
            if (withRootSlash) {
                return payload.tr?.search(/^http[s]?:\/\//) === 0 ? payload.tr.replace(/^\/|\/$/g, '') : rootSlash+payload.tr.replace(/^\/|\/$/g, '')
            } else {
                return payload[locale.value];
            }
        } else {
            return rootSlash+"";
        }
    }
}

export function objectToStringKey(obj) {
    const params = [];

    for (const key in obj) {
        if (typeof obj[key] !== 'undefined') {
            params.push(`${encodeURIComponent(obj[key])}`);
        }
    }

    return params.join('::');
}

export const getTextColorClass = (value) => {
    switch (value) {
        case "primary_color" :
            return "text-primary";
        case "secondary_color" :
            return "text-secondary";
        case "black_color" :
            return "text-black";
        case "white_color" :
            return "text-white";
        default :
            return "";
    }
}
export const verticalClass = (value, flexCol = false) => {
    switch (value) {
        case "middle" :
            return flexCol ? "justify-center" : "items-center";
        case "bottom" :
            return flexCol ? "justify-end" : "items-end";
        default : // top
            return flexCol ? "justify-start" : "items-start";
    }
}

export const horizontalClass = (value, flexCol = false) => {
    switch (value) {
        case "center" :
            return flexCol ? "items-center" : "justify-center";
        case "right" :
            return flexCol ? "items-end" : "justify-end";
        default : // left
            return flexCol ? "items-start" : "justify-start";
    }
}

export const imgPositionClass = (vValue, hValue) => {
    return vValue === "top" && hValue === "center" ? "object-top" :
        vValue === "bottom" && hValue === "center" ? "object-bottom" :
            vValue === "middle" && hValue === "left" ? "object-left" :
                vValue === "middle" && hValue === "right" ? "object-right" :
                    vValue === "top" && hValue === "left" ? "object-left-top" :
                        vValue === "bottom" && hValue === "left" ? "object-left-bottom" :
                            vValue === "top" && hValue === "right" ? "object-right-top" :
                                vValue === "bottom" && hValue === "right" ? "object-right-bottom" :
                                    "object-center"
}

/**
 * Metni url'de kullanılabilir hale çevirir. Boşluklar tireye çevrilir,
 * alfanumerik olmayan katakterler silinir.
 *
 * Transform text into a URL path slug(with Turkish support).
 * Spaces turned into dashes, remove non alnum
 *
 * @param text
 */
export function slugify(text) {
    let trMap = {
        'çÇ':'c',
        'ğĞ':'g',
        'şŞ':'s',
        'üÜ':'u',
        'ıİ':'i',
        'öÖ':'o'
    };
    for(let key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();
}

export const viewResponseError = (errors, fieldName) => {

    if (typeof errors === 'undefined') {
        return null;
    }

    const asArray = Object.entries(errors);
    const message = asArray.filter(([key, value]) => {
        if (key === fieldName) {
            return value;
        }
    }).map(([key, value]) => value[0]);

    return message[0];
}

export function getUniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export function inArray(source, array){

    if (typeof source === 'undefined' || source === null || typeof array === 'undefined') {
        return false;
    }

    if (!Array.isArray(array) && typeof array !== 'object') {
        return true;
    }

    const string = source;

    let value = 0;

    if (!Array.isArray(array) && typeof array === 'object') {
        array = Object.values(array)
    }

    array.filter(function(word){
        value += word === string ? 1 : 0;
    });
    return value
}

const notInArr = function notInArr(item) {
    return this.indexOf(item) === -1
};

export function addCheckedSubset(current, add) {
    if (!current.length) return add

    // Add only messages which are not already checked
    return current.concat(add.filter(notInArr, current))
}

export function removeCheckedSubset(current, remove) {
    // Return only messages which are not in subset
    return current.filter(notInArr, remove)
}


export const getImageForDevice = (image) => {
    const { isDesktopOrTablet } = useDevice();

    if (typeof image === 'undefined' || !image?.desktopImage ) {
        return "#";
    }

    if (isDesktopOrTablet) {
        return image.desktopImage
    } else {
        return image?.mobileImage ? image.mobileImage : image.desktopImage
    }
}

export const getImage = (image) => {
    if (!image || typeof image === 'undefined' || image === 'null') {
        return "/images/no-image.png";
    } else {
        return image
    }
}

export const getCharsOfName = (value) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...value.matchAll(rgx)] || [];

    return (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
}

export const getCurrencySymbol = (currencyCode, withString = false) => {
    switch (currencyCode) {
        case "TRY" :
            return withString ? "TL" : "₺";
        case "USD" :
            return withString ? "USD" : "$";
        case "EUR" :
            return withString ? "EUR" : "€";
        default :
            return currencyCode;
    }
}

export const formatPrice = (price, currency = 'TRY', useCode = false) => {
    const storeConfig = useStoreConfig()
    const { accountDecimalRate } = storeToRefs(storeConfig)

    let currencyFormat = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: currency ? currency : 'TRY',
        minimumFractionDigits: accountDecimalRate.value,
        maximumFractionDigits: accountDecimalRate.value,
        currencyDisplay : useCode ? "code" : "symbol" // "code" | "symbol"
    });

    return currencyFormat.format(price);
}

export function checkAndAddSlash(str) {
    if (typeof str !== 'string') return str;
    
    if (str.startsWith("/") || str.startsWith("http")) {
        return str;
    } else {
        return "/" + str;
    }
}

export const customRound = (number) => {
    const parts = number.toString().split(".");
    const decimalNumber = parts.length > 1 ? parseInt(parts[1].substring(0, 2), 10) : 0;

    if (decimalNumber >= 50) {
        return Math.ceil(number);
    } else {
        return Math.floor(number);
    }
}

export const relDiff = (x, y) => {
    return   ((x - y) / x) * 100;
}

export const checkFileExtension = (filename) => {
    return filename.split('.').pop()
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export default () => {
    const storeConfig = useStoreConfig()
    const { wholesaleConfig, isB2BAccount } = storeToRefs(storeConfig)

    const {status, data} = useAuth()

    const isWholesaleUser = computed(() => status?.value === 'authenticated' && data?.value?.customer_type === 1)

    const isShowMenu = computed(() => {
        if (status?.value === 'authenticated' && isWholesaleUser?.value && wholesaleConfig.value?.hide_menu) {
            return false
        } else if (status?.value === 'unauthenticated' && !isWholesaleUser?.value) {
            return true
        } else {
            return true
        }
    })

    const isHideBreadCrumbs = computed(() => isB2BAccount.value && isWholesaleUser?.value && wholesaleConfig.value?.hide_breadcrumbs);

    const isShowFilter = computed(() => !isWholesaleUser?.value || isWholesaleUser?.value && !wholesaleConfig.value?.hide_filters)

    const isShowFooter = computed(() => !isWholesaleUser?.value || isWholesaleUser?.value && !wholesaleConfig.value?.hide_footer)

    const showOnlyB2C = computed(() => isB2BAccount.value && !isWholesaleUser?.value || !isB2BAccount.value)

    const isShowMobileSearch = useState('isShowMobileSearch', () => false)

    const isVatExcludeForB2B = computed(() => (!!(typeof wholesaleConfig.value?.vat_exclude !== 'undefined' && wholesaleConfig.value?.vat_exclude)) && isWholesaleUser?.value)

    const shownVatIncludeText = computed(() => !!(typeof wholesaleConfig.value?.show_vat_include_text !== 'undefined' && wholesaleConfig.value?.show_vat_include_text))

    const hidePricesForGuest = computed(() => wholesaleConfig.value?.hide_price_from_guest && status?.value === 'unauthenticated');

    const priceUpdateModeActiveForB2B = computed(() => wholesaleConfig.value?.hide_prices);

    return {
        isShowMenu,
        isShowFilter,
        isB2BAccount,
        isShowFooter,
        isVatExcludeForB2B,
        shownVatIncludeText,
        showOnlyB2C,
        isHideBreadCrumbs,
        isShowMobileSearch,
        checkFileExtension,
        getImageForDevice,
        getCharsOfName,
        getCurrencySymbol,
        relDiff,
        priceUpdateModeActiveForB2B,
        hidePricesForGuest
    }
}