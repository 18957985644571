<template>
  <div class="space-y-2 mt-4" v-if="product?.variants.filter((i) => (i.size.hasOwnProperty('label') && i.size?.label !== 'STD')).length >= 1">
    <div class="flex justify-between items-center text-base">
      <div>{{ attributeTranslations.size }}</div>
      <button type="button" class="block hover:underline focus:outline-none" v-if="sizeChartImage" @click.prevent="sizeChartVisible=true">{{$t('listing.size-table')}}</button>
    </div>
    <div class="flex flex-wrap gap-2.5 text-sm">
      <div v-for="(variant, idx) in selectedColorGroup" :key="`v-${idx}`">
        <div v-if="parseInt(variant?.primaryStockData?.quantity) <= 0" id="disableProduct" class="size-label-wrapper">
          <div class="text-gray-200">
            {{ variant?.size?.label }}
          </div>
        </div>
        <div
            class="size-label-item"
            :class="selectedVariant?.size?.value === variant.size.value ? 'active' : 'inactive'"
            @click="setSelectedSize(variant?.size?.value); setSelectedVariant(variant);"
            v-else
        >
          {{ variant?.size?.label }}
        </div>
      </div>
    </div>

    <!-- Size Chart -->
    <ClientOnly>
      <Teleport to="body">
        <FormModal
            without-header
            width-class="max-w-2xl"
            v-model="sizeChartVisible"
        >
          <NuxtImg :src="sizeChartImage" class="w-full h-full" />
        </FormModal>
      </Teleport>
    </ClientOnly>

  </div>
</template>

<script setup>
const props = defineProps(['sizeChartImage']);
const router = useRouter();
const route = useRoute();

const {
  product,
  selectedVariant,
  setSelectedVariant,
  attributeTranslations,
  sizeChartVisible,
  setSelectedSize,
  selectedColorGroup
} = await useProduct()

</script>

<style scoped>

</style>