<template>
  <component :is="ProductSliderTemplate" />
</template>

<script setup>

const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const ProductSliderTemplate = shallowRef();
switch(unref(themeComponents)?.product_slider_component) {
  case '2':
    ProductSliderTemplate.value = resolveComponent('LazyTemplatesProductSlider2')
    break;
  case '3':
    ProductSliderTemplate.value = resolveComponent('LazyTemplatesProductSlider3')
    break;
  case '4':
    ProductSliderTemplate.value = resolveComponent('LazyTemplatesProductSlider4')
    break;
  default :
    ProductSliderTemplate.value = resolveComponent('LazyTemplatesProductSlider1');
}

</script>