// export const isOutOfStock = (variants) => {
//     if (variants?.contains((value) => parseInt(value?.primaryStockData?.quantity) !== 0)) {
//         return false;
//     } else {
//         return true;
//     }
// };

export const discountRate = (variant, isWholesaleRate = false) => {
    if (variant?.primaryStockData?.discount?.config?.type === 'percent') {
        return variant?.primaryStockData?.discount?.config?.value
    }

    return Math.round((1 - variant?.primaryStockData?.discountPrice / variant?.primaryStockData[isWholesaleRate ? 'wholesalePrice' : 'price']) * 100)
}
export const discountRateIsExits = (variant, isWholesaleRate = false) => typeof variant !== 'undefined' && variant?.primaryStockData?.discountPrice > 0 && variant?.primaryStockData?.discountPrice !== variant?.primaryStockData[isWholesaleRate ? 'wholesalePrice' : 'price']

export default function () {
    const route = useRoute()

    const storeConfig = useStoreConfig()
    const {baseConfig, listingConfig, wholesaleConfig} = storeToRefs(storeConfig)

    // Tükenen ürünlerin fiyatlarını gizle
    const hidePriceForSoldOut = computed(() => unref(listingConfig)?.hide_price_for_sold_out_products)

    const showProductUnit = computed(() => unref(listingConfig)?.show_product_unit)

    // Üstü çizili fiyat göster
    const showListPrice = computed(() => unref(listingConfig)?.show_list_prices)

    const showListPriceAndCampaignTogether = computed(() => unref(listingConfig)?.show_list_price_and_campaign_together)

    const selectedWarehouse = useState("selectedWarehouse", () => (unref(baseConfig)?.is_b2b_active && unref(wholesaleConfig)?.default_warehouse_id) ? unref(wholesaleConfig).default_warehouse_id : unref(baseConfig)?.default_warehouse_id);

    const isSearchPage = computed(() => route.path.startsWith('/search'))

    const isDouble = computed(() => listingConfig.value?.double_product_card_list_on_mobile ? listingConfig.value?.double_product_card_list_on_mobile : false);

    const hideBrand = computed(() => listingConfig.value?.hide_brand_on_card ? listingConfig.value?.hide_brand_on_card : false);

    const maxQtyForProduct = (product) => {
        if (unref(wholesaleConfig)?.only_show_default_warehouse_qty === 1) {
            return typeof product?.selectedVariant?.qtySumForAllWarehouse === 'string' ? parseInt(product?.selectedVariant?.qtySumForAllWarehouse) : product?.selectedVariant?.qtySumForAllWarehouse;
        }

        return typeof product?.selectedVariant?.primaryStockData?.quantity === 'string' ? parseInt(product?.selectedVariant?.primaryStockData?.quantity) : product?.selectedVariant?.primaryStockData?.quantity;
    }

    return {
        selectedWarehouse,
        isSearchPage,
        hidePriceForSoldOut,
        showListPrice,
        showListPriceAndCampaignTogether,
        isDouble,
        showProductUnit,
        hideBrand,
        discountRate,
        discountRateIsExits,
        maxQtyForProduct
    }
}
