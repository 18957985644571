import {v4 as uuidv4} from "uuid";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const checkoutId = useCookie('checkoutId');
    const basketId = useCookie('basketId');
    const countryCode = useCookie('countryCode'); // TODO : multi region

    // Basket ID set ediyoruz
    if (!basketId.value && !to.path.startsWith('/api/payment-callback')) {
        basketId.value = uuidv4();
    }

    // Checkout ID set ediyoruz
    if (!checkoutId.value && !to.path.startsWith('/api/payment-callback')) {
        checkoutId.value = uuidv4();
    }

    if(!countryCode.value) {
        countryCode.val = 'TR'
    }

    // TODO : sepette oturum açıldığı zaman anasayfaya yönlendiriyor. Sayfa bilgisi kaydedilmesi lazım ki, oturum açıldığında aynı sayfaya yönlendirme yapalım.
})
