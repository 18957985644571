<template>
  <div>

    <!-- Widget Header -->
    <div class="flex justify-between items-center mb-2 gap-5 flex-wrap">
      <div class="font-bold text-2xl md:text-3xl order-1 text-primary">{{ widgetTitle }}</div>

      <template v-if="$slots.actions">
        <slot name="actions" />
      </template>

      <div class="flex gap-2 order-3" v-if="slider && showNavigation && products.length > 5">
        <button
            class="swiper--top-prev"
            @click="slider.prev()"
        >
          <ChevronLeftIcon class="swiper--top-icon" />
        </button>
        <button
            class="swiper--top-next"
            @click="slider.next()"
        >
          <ChevronRightIcon class="swiper--top-icon" />
        </button>
      </div>
    </div>

    <div ref="container" class="keen-slider">
      <div
          v-for="(product, index) in products"
          :key="`sp-${index}`"
          class="keen-slider__slide flex flex-col items-center justify-start"
      >
        <LayoutComponentProductCard :product="product" />
      </div>
    </div>

  </div>
</template>

<script setup>

import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
  widgetTitle : {
    default : null
  },
  products: {
    default : []
  },
  navigation : {
    type : Boolean,
    default : false
  },
  breakpoints : {
    default : {
      '(max-width: 767px)': {
        slides: { perView: 1.5, spacing: 16 },
      },
      '(min-width: 320px and max-width: 767px)': {
        slides: { perView: 2.5, spacing: 16 },
      },
      '(min-width: 768px)': {
        slides: { perView: 5, spacing: 16 },
      },
    }
  },
  loop : {
    type : Boolean,
    default : false
  },
  showNavigation : {
    type : Boolean,
    default : false
  }
});

const {autoPlayPlugin} = useSlider()
const current = ref(1)

const [container, slider] = useKeenSlider({
  initial: current.value,
  created: (s) => {
    current.value = s.track.details.rel
  },
  slideChanged: (s) => {
    current.value = s.track.details.rel
  },
  loop: props.loop,
  breakpoints: props.breakpoints,
  slides: { perView: 5 },
}, [
  autoPlayPlugin
])
</script>