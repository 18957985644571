<template>
  <div v-if="productConfig?.is_show_information_badges && informationBadges?.length > 0">
    <div class="product-highlights">
      <div class="highlight" v-for="(badge, index) in informationBadges" :key="`f-${index}`">
        <span class="fill-primary" v-html="badge.icon"></span>
        <div class="highlight--content">
          <span class="highlight--title">
            {{ getLocaleString(badge.name) }}
          </span>
          <span class="highlight--description" v-if="badge.description">
            {{ getLocaleString(badge.description) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const {productConfig} = storeToRefs(storeConfig)
const {isWholesaleUser} = await useCustomer()

const informationBadges = computed(() => {

  if (!productConfig.value?.is_show_information_badges || !productConfig?.value?.information_badges.length) {
    return []
  }

  if (isWholesaleUser.value) {
    return productConfig.value?.information_badges.filter((i) => inArray(i.visible, ['all', 'b2b']))
  } else {
    return productConfig.value?.information_badges.filter((i) => inArray(i.visible, ['all', 'b2c']))
  }
})

</script>