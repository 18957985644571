<template>
  <div v-if="discountDescription" class="inline-flex items-center bg-primary/10 text-primary-600 font-medium px-4 py-2 rounded-lg text-base">
    <div class="relative flex items-center justify-center h-4 w-4 mr-1.5 -z-10">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-40"></span>
      <BoltIcon class="w-full h-full"/>
    </div>
    {{ discountDescription }}
  </div>
</template>

<script setup>
import {BoltIcon} from "@heroicons/vue/24/outline";

const {
  selectedVariant
} = await useProduct()

const discountDescription = computed(() => selectedVariant.value.primaryStockData?.discount.description)

</script>

<style scoped>

</style>