<template>
  <main class="app-container">

    <NuxtLayout v-if="!isMaintenanceMode && subscriptionIsActive">
        <NuxtPage/>
    </NuxtLayout>

    <!-- Bakım Modu -->
    <template v-else-if="isMaintenanceMode && subscriptionIsActive">
      <MaintenanceMode />
    </template>

    <!-- Abonelik Bitti -->
    <template v-else-if="!subscriptionIsActive">
      <SubscriptionExpired />
    </template>

    <Teleport to="body">
      <ClientOnly>
        <FormModal
            :title="$t('auth.sign-in-to-your-account')"
            width-class="max-w-sm"
            v-model="authModalIsVisible"
        >
          <AuthFormLogin/>
        </FormModal>

        <FormModal
            without-header
            z-index-class="z-300"
            width-class="max-w-7xl min-h-[100px]"
            v-model="showQuickModal"
            @close="closeQuickModal"
            :close-on-click-outside="false"
        >
          <div class="absolute top-0 right-0 pt-3 pr-4 z-100">
            <FormButton @click="closeQuickModal" size="sm" color="flex gap-2.5 text-gray-500">
              <ArrowLeftIcon class="md:h-4 md:w-4 h-3 w-3"/>
              <span>{{$t('app.go-back')}}</span>
              <kbd class="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-gray-50 px-1.5 font-mono text-[10px] font-medium text-gray-500 opacity-100">
                ESC
              </kbd>
            </FormButton>
          </div>
          <LayoutProduct class="-mt-5 quick-view-wrapper" :slug="quickModalUrl" modal />
        </FormModal>
      </ClientOnly>
    </Teleport>

    <VueCookieAcceptDecline
        :debug="false"
        :disableDecline="false"
        :showPostponeButton="false"
        @clicked-accept="cookieClickedAccept"
        @status="cookieStatus"
        elementId="myPanel1"
        position="bottom-left"
        ref="myPanel1"
        transitionName="slideFromBottom"
        type="floating"
    >
      <!-- Optional -->
      <template #message>
            <span class="text-base">
              {{$t('cookie.message')}}
            </span>
      </template>

      <!-- Optional -->
      <template #declineContent><span class="text-base">{{$t('cookie.decline')}}</span></template>

      <!-- Optional -->
      <template #acceptContent><span class="text-base">{{$t('cookie.accept')}}</span></template>
    </VueCookieAcceptDecline>
  </main>
</template>

<script setup>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import 'keen-slider/keen-slider.min.css'
import {ArrowLeftIcon} from "@heroicons/vue/24/outline";

const storeConfig = useStoreConfig()
const { subscriptionIsActive, isMaintenanceMode, currency, baseConfig, metaTags, scriptTags } = storeToRefs(storeConfig)

// Config bilgilerini çekip pinia store'a kaydediyoruz (Sadece 1 kere çekiyoruz)
await callOnce(storeConfig.fetch)

const {
  freeShippingLimit,
  freeShippingRemainAmount,
  selectedPaymentGateway,
  selectedShippingOption,
  basketItems,
  basketCount,
  basketTotal,
  oldBasketTotal,
  basketCurrency,
  promoCode,
  promoCodeApplied,
  promoCodeMessage,
  offerStatus,
  basketLoading,
  isBasketContentVisible,
  vatIncludedBasketTotal,
  vatIncludedOldBasketTotal,
  saveAmount,
  currencyConvertedLines,
  localCheckoutTotal,
  basketCurrencyRate
} = await useBasket()

const {closeQuickModal, showQuickModal, quickModalUrl} = useQuickProductView()

const {authModalIsVisible} = await useCustomer()

const {locale} = useI18n()
const router = useRouter()

// Sepet içeriğini lazy load olarak çağırıyoruz
const {status, data: basket} = useLazyAsyncData("userBasket", () => $fetch('/api/basket', {
  query: {
    paymentMethod: selectedPaymentGateway.value?.methodType,
    selectedCargo: selectedShippingOption.value?.providerCode,
    currency: currency.value
  },
  onRequest() {
    basketLoading.value = true
  },
  onRequestError() {
    basketLoading.value = false
  },
  async onResponse() {
    basketLoading.value = false
  }
}), {
  key: "userBasket",
  server: false,
  watch: [() => selectedPaymentGateway.value?.methodType, () => selectedShippingOption.value?.providerCode]
})
watch(basket, function (data) {
  basketItems.value = data?.basketContent;
  basketCount.value = data?.basketCount;
  basketTotal.value = data?.basketTotal;
  currencyConvertedLines.value = data?.converted;
  localCheckoutTotal.value = data?.localCheckoutTotal;
  oldBasketTotal.value = data?.originalBasketTotal;
  basketCurrency.value = data?.basketCurrency;
  basketCurrencyRate.value = data?.basketCurrencyRate;
  vatIncludedBasketTotal.value = data?.vatIncludedBasketTotal;
  vatIncludedOldBasketTotal.value = data?.vatIncludedOriginalBasketTotal;
  saveAmount.value = data?.saveAmount;
  promoCode.value = data?.promoCode;
  promoCodeApplied.value = data?.promoCodeApplied;
  promoCodeMessage.value = data?.promoCodeMessage;
  offerStatus.value = data?.offerStatus;
  freeShippingLimit.value = data?.freeShippingLimit;
  freeShippingRemainAmount.value = data?.freeShippingRemainAmount;
})

watch(status, (val) => {
  basketLoading.value = val === 'pending'
})

const metaInfo = [
  {name: 'description', content: baseConfig.value?.seo_description[unref(locale)]}
];

if (typeof baseConfig.value?.seo_keywords[unref(locale)] !== 'undefined') {
  metaInfo.push({name: 'keywords', content: baseConfig.value?.seo_keywords[unref(locale)]})
}

const canonicalUrl = computed(() => useRequestURL().origin + useRequestURL().pathname)
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${baseConfig.value?.site_title[unref(locale)]}` : baseConfig.value?.site_title[unref(locale)];
  },
  meta: [
    ...metaInfo,
    ...metaTags.value
  ],
  script: scriptTags.value,
  link: [
    {
      rel: 'canonical',
      href: canonicalUrl.value,
    },
  ],
})

useServerSeoMeta({
  ogUrl : canonicalUrl.value
})

useSchemaOrg([
  defineOrganization({
    name: baseConfig.value?.site_alias[unref(locale)],
  }),
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  defineWebSite({
    name: baseConfig.value?.site_alias[unref(locale)],
    description: baseConfig.value?.seo_description[unref(locale)],
    inLanguage: locale.value
  }),
  defineWebPage(),
])

const isCookieAccepted = useState('isCookieAccepted', () => false)

const cookieClickedAccept = () => {
  isCookieAccepted.value = true;
}

const cookieStatus = computed(() => isCookieAccepted.value ? 'accepted' : null)

onMounted(() => {
  localStorage.getItem('vue-cookie-accept-decline-myPanel1') === 'accept' ? isCookieAccepted.value = true : isCookieAccepted.value = false;
});

// Bir hata oluştuğunda ve sayfa değiştiğinde ilgili hatayı temizlemek için kullanıyoruz
useRouter().afterEach(async () => {
  isBasketContentVisible.value = false;
  await clearError();
});

</script>