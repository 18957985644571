import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAddCircle, LazySvgoAddToCart, LazySvgoCancel, LazySvgoFailed, LazySvgoFast, LazySvgoGrid, LazySvgoHamburgerMenu, LazySvgoList, LazySvgoLoading, LazySvgoLogout, LazySvgoPlus, LazySvgoSearch, LazySvgoShoppingBag, LazySvgoTriangle, LazySvgoUser, LazySvgoWhatsapp } from '#components'
const lazyGlobalComponents = [
  ["SvgoAddCircle", LazySvgoAddCircle],
["SvgoAddToCart", LazySvgoAddToCart],
["SvgoCancel", LazySvgoCancel],
["SvgoFailed", LazySvgoFailed],
["SvgoFast", LazySvgoFast],
["SvgoGrid", LazySvgoGrid],
["SvgoHamburgerMenu", LazySvgoHamburgerMenu],
["SvgoList", LazySvgoList],
["SvgoLoading", LazySvgoLoading],
["SvgoLogout", LazySvgoLogout],
["SvgoPlus", LazySvgoPlus],
["SvgoSearch", LazySvgoSearch],
["SvgoShoppingBag", LazySvgoShoppingBag],
["SvgoTriangle", LazySvgoTriangle],
["SvgoUser", LazySvgoUser],
["SvgoWhatsapp", LazySvgoWhatsapp],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
