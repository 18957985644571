import { default as _91slug_93B3g4j3DZJ4Meta } from "/home/forge/e-trik.com/pages/[slug].vue?macro=true";
import { default as addressL3lwTLeyGZMeta } from "/home/forge/e-trik.com/pages/account/address.vue?macro=true";
import { default as _91id_93fYxqGWPYy7Meta } from "/home/forge/e-trik.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93dJjaj68K2QMeta } from "/home/forge/e-trik.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexZ8udDLPRdOMeta } from "/home/forge/e-trik.com/pages/account/orders/index.vue?macro=true";
import { default as reviewOpND8A8pw6Meta } from "/home/forge/e-trik.com/pages/account/review.vue?macro=true";
import { default as _91id_93UAgQij18IUMeta } from "/home/forge/e-trik.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexka4fk5fC9zMeta } from "/home/forge/e-trik.com/pages/account/statement/index.vue?macro=true";
import { default as userrq8tqbbde4Meta } from "/home/forge/e-trik.com/pages/account/user.vue?macro=true";
import { default as wishlistTJwinfneNbMeta } from "/home/forge/e-trik.com/pages/account/wishlist.vue?macro=true";
import { default as cartKf7iG5K3KVMeta } from "/home/forge/e-trik.com/pages/cart.vue?macro=true";
import { default as indexQ9367eJgjGMeta } from "/home/forge/e-trik.com/pages/checkout/index.vue?macro=true";
import { default as paymentNt1nfhJEKWMeta } from "/home/forge/e-trik.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientl2M9PT4LPfMeta } from "/home/forge/e-trik.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/e-trik.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestHFfBSBMAVvMeta } from "/home/forge/e-trik.com/pages/customer/request.vue?macro=true";
import { default as successB6oOpKlqEcMeta } from "/home/forge/e-trik.com/pages/customer/success.vue?macro=true";
import { default as iletisimQZaRGPbTYLMeta } from "/home/forge/e-trik.com/pages/iletisim.vue?macro=true";
import { default as indexeLA6VnLJIJMeta } from "/home/forge/e-trik.com/pages/index.vue?macro=true";
import { default as loginzaiq0KXBXBMeta } from "/home/forge/e-trik.com/pages/login.vue?macro=true";
import { default as offerONIrSOlZ8lMeta } from "/home/forge/e-trik.com/pages/offer.vue?macro=true";
import { default as statusijj83sadJoMeta } from "/home/forge/e-trik.com/pages/order/status.vue?macro=true";
import { default as track7p5Xr0H7PDMeta } from "/home/forge/e-trik.com/pages/order/track.vue?macro=true";
import { default as forgotJh2tZgoRG7Meta } from "/home/forge/e-trik.com/pages/password/forgot.vue?macro=true";
import { default as resetLH66Hwh9SwMeta } from "/home/forge/e-trik.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationINr7QfBhliMeta } from "/home/forge/e-trik.com/pages/payment-notification.vue?macro=true";
import { default as summarygu123f2nGHMeta } from "/home/forge/e-trik.com/pages/payment/summary.vue?macro=true";
import { default as register1ZDbqAE4pRMeta } from "/home/forge/e-trik.com/pages/register.vue?macro=true";
import { default as searchrtsjilDZQwMeta } from "/home/forge/e-trik.com/pages/search.vue?macro=true";
import { default as locationsvJh8LmLqK6Meta } from "/home/forge/e-trik.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93B3g4j3DZJ4Meta || {},
    component: () => import("/home/forge/e-trik.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressL3lwTLeyGZMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93fYxqGWPYy7Meta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93dJjaj68K2QMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexZ8udDLPRdOMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewOpND8A8pw6Meta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93UAgQij18IUMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexka4fk5fC9zMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userrq8tqbbde4Meta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistTJwinfneNbMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/e-trik.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexQ9367eJgjGMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentNt1nfhJEKWMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/e-trik.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/e-trik.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/e-trik.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/e-trik.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexeLA6VnLJIJMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginzaiq0KXBXBMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerONIrSOlZ8lMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/e-trik.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/e-trik.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotJh2tZgoRG7Meta || {},
    component: () => import("/home/forge/e-trik.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetLH66Hwh9SwMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/e-trik.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/e-trik.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: register1ZDbqAE4pRMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchrtsjilDZQwMeta || {},
    component: () => import("/home/forge/e-trik.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/e-trik.com/modules/locationsPage/pages/locations.vue")
  }
]