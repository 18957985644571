<template>
  <div
      class="container py-20 text-center flex flex-col items-center justify-center h-screen"
  >
    <div
        v-if="error.statusCode == 404"
        class="flex flex-col items-center justify-center text-center py-4 px-4 space-y-10"
    >
      <div>
        <NuxtImg
            src="/images/404-not-found-featured-television.gif"
            width="300"
            height="300"
            alt="not-found"
            class="object-contain h-64 w-64"
        />
      </div>
      <div>
        <h1 class="text-4xl">Sayfa Bulunamadı - 404</h1>
        <p class="font-medium text-primary">
          Aradığınız sayfa mevcut değil. Taşınmış veya silinmiş olabilir.
        </p>
      </div>
      <div>
        <FormButton color="gray" @click="handleError">
          <ArrowLeftIcon class="text-secondary h-5 w-5 mr-2"/>
          <span>Anasayfaya Dön</span>
        </FormButton>
      </div>
    </div>
    <div
        v-if="error.statusCode == 500"
        class="flex flex-col items-center justify-center text-center py-4 px-4 space-y-10"
    >
      <div>
        <NuxtImg
            src="/images/500.png"
            width="425"
            height="342"
            alt="500"
            class="object-contain"
        />
      </div>
      <div class="font-medium text-primary">
        <h1 class="text-4xl">Hata Kodu : {{error.statusCode}}</h1>
        <p>
          Bir sorun oluştu. Şu anda bu konuyla ilgileniyoruz, lütfen daha sonra
          tekrar deneyiniz.
        </p>
        <DevOnly>
          <div class="text-secondary" v-if="error.message">
            <p>Hata Mesajı: <span class="text-primary">{{ error.message }}</span></p>
            <div>Stack: <pre class="text-primary text-xs mt-1">{{ error.stack }}</pre></div>
          </div>
        </DevOnly>

      </div>
      <div>
        <NuxtLinkLocale href="/">
          <FormButton color="gray">
            <ArrowLeftIcon class="text-secondary h-5 w-5 mr-2"/>
            <span>Anasayfaya Dön</span>
          </FormButton>
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ArrowLeftIcon} from "@heroicons/vue/24/outline";

const props = defineProps(["error"]);

const pageTitle = computed(() => {
  if (props.error.statusCode == 404) {
    return 'Sayfa Bulunamadı!'
  } else {
    return 'Sunucu Hatası'
  }
})

useHead({
  title: pageTitle.value
})

const handleError = () => clearError({redirect: "/"});
</script>

<style scoped></style>
