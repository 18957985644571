import FloatingVue, {VTooltip} from 'floating-vue'
import 'floating-vue/dist/style.css'

export default defineNuxtPlugin(nuxtApp => {
    FloatingVue.options.themes.tooltip.delay.show = 50
    FloatingVue.options.distance = 8;

    nuxtApp.vueApp.directive('tooltip', VTooltip);
})

// ## Usage ##
// v-tooltip="product?.selectedVariant?.primaryStockData?.warehouseName"