<template>
  <div class="flex items-center space-x-2 text-primary" v-if="productReviews && productReviews.hasOwnProperty('totalReviews') && showOnlyB2C">
    <FormRatingStar :grade="productReviews?.totalPoints ? productReviews?.totalPoints : 0"/>
    <span v-if="productReviews?.totalReviews === 0" class="text-sm text-primary mb-2">{{ $t('listing.no-reviews') }}</span>
    <span v-if="productReviews?.totalReviews !== 0" class="text-sm text-primary mb-2">{{ productReviews.totalReviews }}</span>
  </div>
</template>

<script setup>
const {showOnlyB2C} = useHelper()

const {
  product,
  meta,
  selectedVariant,
  installments,
} = await useProduct()

const { pending, data: productReviews } = useLazyAsyncData(`productReviews::${product?.value?.productId}`, () => $fetch(`/api/product/reviews`, {
  params : {
    productId : product?.value?.productId
  },
}), {
  key: `productReviews::${product?.value?.productId}`,
  server : false
})

</script>

<style scoped>

</style>