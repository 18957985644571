<template>
  <TransitionRoot as="template" :show="openModal">
    <Dialog as="div" class="relative z-300" @close="setOpenModal(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-300 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg text-primary bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div>
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-secondary">{{ $t('comps.question.ask-to-seller') }}</DialogTitle>
                  <div class="mt-3">
                    <p class="text-base">
                      {{ $t('comps.question.product') }}: {{ product.productTitle }}
                    </p>
                  </div>
                  <div class="mt-3">
                    <FormInput
                      :label="$t('comps.question.your-question')"
                      input-type="textarea"
                      name="question"
                      :error="viewResponseError(errors, 'question')"
                      required
                      v-model="question"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <FormButton
                  size="md"
                  color="primary"
                  class="w-full uppercase"
                  type="button"
                  :loading="loading"
                  @click="addQuestion(question)"
                >
                  {{ $t('app.submit') }}
                </FormButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const {$showToast} = useNuxtApp();
const props = defineProps(["openModal", "setOpenModal", "product"]);
const loading = ref(false);
const errors = useState("errors", () => []);
const question = useState('question');

const addQuestion = async (question) => {
  loading.value = true;
  errors.value = [];

  const { data, error } = await useFetch("/api/productQuestions", {
    method: "post",
    body: {
      productId: props.product.productId,
      content: question,
    },
    async onResponse({ request, response, options }) {
      props.setOpenModal(false);
    },
    onResponseError({ request, response, options, error }) {
      // Handle the response errors
      errors.value = response._data.data;
    },
  });

  $showToast(data.value.message, data.value.status);

  loading.value = false;
  return data;
};
</script>
