<template>
    <component :is="ProductDetailTemplate">

        <!-- Breadcrumbs alanı -->
        <template #breadcrumbs v-if="!modal">
            <SectionBreadcrumb class="mb-2.5 sm:mb-2 mt-2.5 sm:mt-0" :withHome="true" v-if="!isHideBreadCrumbs"/>
        </template>

        <!-- Görsel slider -->
        <template #slider>
            <template v-if="!selectedVariant.imagesIsExists">
              <NuxtImg
                  v-if="selectedVariant?.thumbnail"
                  :src="getImage(selectedVariant.thumbnail)"
                  :alt="selectedVariant?.title"
                  class="object-contain w-full h-full max-w-xl mx-auto"
              />
            </template>
            <LayoutComponentProductSlider :product="product" v-else/>
        </template>

        <!-- Ürün başlık bilgisi -->
        <template #title>
            <h1 class="product-title">{{ selectedVariant?.title }}</h1>
        </template>

        <!-- Ürün marka bilgisi -->
        <template #brand v-if="productConfig?.is_hide_product_brand !== 1">
            <div class="flex items-center space-x-2.5">
              <div class="product-is-new" v-if="selectedVariant?.isNew">
                {{ $t('app.new') }}
              </div>

              <SectionProductBrand v-if="product?.brandName">{{ product.brandName }}</SectionProductBrand>

            </div>
        </template>

        <!-- Ürün marka görseli -->
        <template #brandImage v-if="productConfig?.is_hide_product_brand !== 1">
          <div v-if="product?.brandImage"><NuxtImg :src="product.brandImage" class="h-7 w-auto max-w-[100px] object-contain" /></div>
        </template>

        <!-- Ürün Model Kodu -->
        <template #modelSku v-if="productConfig?.is_hide_product_sku !== 1">
            <span class="text-base font-normal">{{ $t('app.sku') }} : {{ product.modelSku }}</span>
        </template>

        <!-- Ürün Barkodu -->
        <template #primaryBarcode v-if="typeof(productConfig?.is_hide_primary_barcode) !== 'undefined' && productConfig?.is_hide_primary_barcode !== 1">
          <span class="text-base font-normal">{{ $t('app.barcode') }} : {{ selectedVariant.barcodeId }}</span>
        </template>

        <!-- Alternatif Ürün Barkodu -->
        <template #alternativeBarcode v-if="typeof(productConfig?.is_hide_alternative_barcode) !== 'undefined' && productConfig?.is_hide_alternative_barcode !== 1">
          <h2 class="text-base font-normal">{{ $t('app.barcode') }} : {{ selectedVariant.alternativeBarcode }}</h2>
        </template>

        <!-- Ürün İncelemeleri Bilgisi -->
        <template #reviewsInfo v-if="productConfig?.is_hide_product_reviews !== 1">
            <SectionProductReviewsInfo/>
        </template>

        <!-- Ürün Yorumları, Değerlendirmeleri vb. bilgileri (accordion) -->
        <template #productInfoAccordion>
            <SectionProductFeaturesDisclosure/>
        </template>

        <!-- Toptan fiyat seçenekleri -->
        <template #wholesalePrice>
            <component :is="wholesalePriceComponent" v-if="isWholesaleUser && !hidePricesForGuest" />

            <!-- Fiyatları görebilmek için bayi girişi yapınız -->
            <div class="text-base text-gray-600 py-4" v-else-if="hidePricesForGuest">
              <i18n-t keypath="listing.please-login-for-the-prices" tag="div">
                <a href="/login" class="text-login">{{$t('listing.dealer-login')}}</a>
              </i18n-t>
            </div>
        </template>

        <!-- Son kullanıcıya gösterilen fiyat bilgisi -->
        <template #productPrice v-if="!isWholesaleUser && !(parseInt(selectedVariant?.primaryStockData?.quantity) <= 0 && hidePriceForSoldOut) && !hidePricesForGuest">
          <SectionProductDetailPrice/>
        </template>

        <!-- Son kullanıcıya gösterilen indirim/kampanya bilgisi -->
        <template #discountLabel>
            <SectionProductDiscountLabel/>
        </template>

        <!-- Son kullanıcıya gösterilen son kalan ürün bilgisi -->
        <template #productCriticalQuantity>
            <SectionProductCriticalQuantity :quantity="quantityValue" />
        </template>

        <!-- Renk seçenekleri -->
        <template #colorOptions v-if="productConfig?.is_hide_product_color !== 1">
            <SectionProductColorOptions/>
        </template>

        <!-- Beden seçenekleri -->
        <template #sizeOptions v-if="productConfig?.is_hide_product_size !== 1">
            <SectionProductSizeOptions :sizeChartImage="sizeChartImage"/>
        </template>

        <!-- Ürün özellikleri -->
        <template #productAttributes v-if="productConfig?.is_show_product_attributes">
            <SectionProductAttributes/>
        </template>

        <!-- Taksit Bilgisi - X TL'den Başlayan Fiyatlarla -->
        <template #installmentInfo
                  v-if="productConfig?.is_show_installment && productConfig?.is_show_installment_badge && lowestInstallmentPrice">
            <p class="mt-4">
                <span class="text-secondary font-bold">{{ lowestInstallmentPrice }}</span>&apos;den başlayan taksitlerle
            </p>
        </template>

        <!-- Ürün birim bilgisi - Adet/Gram/Metre vb -->
        <template #unitInfo>
            <p v-if="selectedVariant.unitName" class="text-sm mb-1">
                <span class="">{{ $t('app.unit') }} : {{ selectedVariant.unitName }}</span>
            </p>
        </template>

        <!-- Sepete Ekle, Favorilere Ekle vb. aksiyon butonları  -->
        <template #shopButtons v-if="(isWholesaleUser || accessType !== 'b2b') && !hidePricesForGuest">
            <component :is="shopButtonsComponent" />
        </template>

        <!-- Benzer Ürünler -->
        <template #highlights v-if="!modal">
            <SectionProductHighlights/>
        </template>

        <!-- Ürün Açıklama, Soru & Cevap, Yorum ve İade Bilgileri -->
        <template #metaInformations>
            <SectionProductFeaturesBar class="sm:mt-16 mt-8"/>
        </template>

        <template #relatedProducts v-if="showOnlyB2C && !modal && showRelatedProducts">
            <SectionProductRelatedProducts :widget-title="$t('widgets.related-products.title')" :product-id="product.productId" prevEl="prevx" nextEl="nextx"/>
        </template>

    </component>
</template>

<script setup>
import collect from "collect.js";

const props = defineProps({
  slug : {
    type : String
  },
  modal : {
    type : Boolean,
    default : false
  }
})

const {
  product,
  productMeta,
  selectedVariant,
  finalPrice,
  lowestInstallmentPrice,
  installments,
  getInstallments,
  productQuestions,
  priceCurrency,
  breadcrumbs,
  attributeTranslations,
  refreshProductSharableLink,
  cartQuantity,
  showRelatedProducts
} = await useProduct()

const {isWholesaleUser} = await useCustomer()
const {status} = await useAuth()

const {locale} = useI18n()
const headers = useRequestHeaders(['cookie'])

const storeConfig = useStoreConfig()
const { themeComponents, accessType, currency, productConfig } = storeToRefs(storeConfig)

const ProductDetailTemplate = shallowRef();

switch (unref(themeComponents)?.product_detail_component) {
    case '1':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetail1')
        break;
    case '2':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetail2')
        break;
    case '3':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetail3')
        break;
    case 'DetailUnver':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetailUnver')
        break;
    case 'DetailAirlife':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetailAirlife')
        break;
    case 'DetailAirlifeWholesale':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetailAirlifeWholesale')
        break;
    case 'DetailOptik':
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetailOptik')
        break;
    default :
        ProductDetailTemplate.value = resolveComponent('LazyTemplatesProductDetail1');
}

const wholesalePriceComponent = shallowRef()
switch (unref(themeComponents)?.product_wholesale_price_component) {
  case 'Airlife':
    wholesalePriceComponent.value = resolveComponent('LazyTemplatesProductWholesalePriceAirlife')
    break;
  case 'AirlifeWholesale':
    wholesalePriceComponent.value = resolveComponent('LazyTemplatesProductWholesalePriceAirlifeWholesale')
    break;
  case 'StandartWholesale1':
    wholesalePriceComponent.value = resolveComponent('LazyTemplatesProductWholesalePriceStandart1')
    break;
  default :
    wholesalePriceComponent.value = resolveComponent('LazyTemplatesProductWholesalePrice');
}

const shopButtonsComponent = shallowRef()
switch (unref(themeComponents)?.shop_buttons_component) {
  case 'ShopButtonsUnver':
    shopButtonsComponent.value = resolveComponent('LazyTemplatesProductShopButtonsUnver')
    break;
  case 'ShopButtonsAirlife':
    shopButtonsComponent.value = resolveComponent('LazyTemplatesProductShopButtonsAirlife')
    break;
  default :
    shopButtonsComponent.value = resolveComponent('LazyTemplatesProductShopButtonsDefault');
}

// New
const {isHideBreadCrumbs, showOnlyB2C, hidePricesForGuest} = useHelper()
const {fbViewContent} = useFacebookPixel()
const {gViewContent} = useGoogleEvents()
const quantityValue = computed(() => parseInt(selectedVariant.value?.primaryStockData?.quantity));

const {
    selectedWarehouse,
    hidePriceForSoldOut
} = useListing()

const {data, error, refresh} = await useAsyncData(
    props.slug,
    () => $fetch('/api/product', {
        method: 'GET',
        key: props.slug,
        query: {
            slug: props.slug,
            warehouseId: selectedWarehouse.value,
            currency: currency.value
        },
        headers
    })
)

if (collect(data.value?.payload).isEmpty()) {
    throw createError({statusCode: 404, statusMessage: 'Page Not Found'})
}

if (error.value) {
    // TODO : throw error
    throw createError({statusCode: 500, statusMessage: 'Page Not Found'})
}

breadcrumbs.value = data.value.head.breadcrumbList
product.value = data.value.payload
attributeTranslations.value = data.value.translations
productMeta.value = data.value.head

const variantCollection = collect(product.value.variants)
    .where('qtySumForAllWarehouse', '>', 0)
    .where('colorCode', product.value.selectedVariant?.colorCode);

selectedVariant.value = product.value?.selectedVariant?.qtySumForAllWarehouse <= 0 && variantCollection.count() > 0 ? variantCollection.first() : product.value.selectedVariant

if (status.value === 'authenticated' && selectedVariant.value?.variantId) {
    const {data: checkWishlist} = useLazyFetch('/api/wishlist-check', {
        method: 'POST',
        body: {
            variantId: selectedVariant.value.variantId
        },
        headers
    })

    watch(checkWishlist, (newValue) => {
        if (newValue) {
            selectedVariant.value.isFavorited = true
        }
    })
}

// Beden Tablosu
const {data: charts} = useLazyAsyncData(
    `productSizeChart::${unref(product)?.productId}`,
    () => $fetch('/api/product/charts', {
        method: 'GET',
        query: {
            productId: unref(product)?.productId
        },
        server: false,
        headers
    })
)

const sizeChartImage = computed(() => unref(charts) && typeof unref(charts) !== 'undefined' && unref(charts)?.imageUrl ? unref(charts).imageUrl : null)

useServerSeoMeta({
  title: () => unref(productMeta)?.title,
  ogTitle: () => unref(productMeta)?.title,
  description: () => unref(productMeta)?.description,
  ogDescription: () => unref(productMeta)?.description,
  // ogImage: 'https://example.com/image.png',
  twitterCard: 'summary_large_image',
})

// aggregateRating: {
//   ratingValue: 88,
//   bestRating: 100,
//   ratingCount: 20,
// },
// review: [
//     {
//         name: 'Awesome product!',
//         author: {
//             name: 'Harlan Wilton',
//         },
//         reviewRating: {
//             ratingValue: 5,
//         },
//     },
// ],

const schemaOrgData = computed(() => {
  return {
    name: unref(selectedVariant)?.title,
    sku: unref(selectedVariant)['barcodeId'],
    image: collect(unref(selectedVariant)?.images).map((i) => {
      return {
        original : "https://cdn.labsoffice.com" + i.original
      };
    }).values().pluck('original').toArray(),
    category: unref(product)?.categories?.length ? getLocaleString(unref(product)?.categories[0][unref(product)?.categories.length -1]?.categoryName) : null,
    offers : [
      {
        price : finalPrice.value,
        priceCurrency : unref(priceCurrency)
      }
    ],
    brand : {
      '@type' : 'Brand',
      name : unref(product)?.brandName
    }
  }
})

// https://schema.org/Product
useSchemaOrg([
    defineProduct(schemaOrgData.value)
])

onMounted(() => {
    fbViewContent(unref(productMeta)?.title, null, [selectedVariant.value?.variantId], finalPrice?.value, selectedVariant.value?.primaryStockData?.currency)
    gViewContent(selectedVariant.value?.variantId, unref(productMeta)?.title, finalPrice?.value, selectedVariant.value?.primaryStockData?.currency)

    // Taksit bilgilerini yükle
    getInstallments()

    // Ürün paylaşım linkini güncelliyoruz
    refreshProductSharableLink()

    if (selectedVariant.value?.unitType === 2) {
      cartQuantity.value = 1000; // default 1 kg
    }
})

onBeforeRouteUpdate(() => {
    cartQuantity.value = 1;
    refreshProductSharableLink()
})

</script>

<style>
#launcher {
    margin: 70px 20px !important;
}
</style>
