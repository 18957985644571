import useValidator from "~/composables/useValidator.js";

export default async () => {
    const {$showToast} = useNuxtApp();
    const {status, data, signIn} = useAuth()
    const {fbCompleteRegistration} = useFacebookPixel();
    const {gCompleteRegistration} = useGoogleEvents();
    const {timer, verificationCode, showRetryButton, showVerificationInput, startTimer, verificationType} = useValidator()

    const basketId = useCookie('basketId');

    const route = useRoute();
    const authModalIsVisible = useState('authModalIsVisible', () => false);
    const user = useState('userData', () => {})

    // Login Form
    const userEmail = useState('userEmail')
    const userPassword = useState('userPassword')
    const rememberMe = useState('rememberMe', () => false)

    // Register Form
    const newFirstName = useState('newFirstName')
    const newLastName = useState('newLastName')
    const newCustomerPhone = useState('newCustomerPhone')
    const newCustomerEmail = useState('newCustomerEmail')
    const newCustomerPassword = useState('newCustomerPassword')
    const newCustomerGender = useState('newCustomerGender')
    const allowAgreement = useState('allowAgreement')
    const allowNotification = useState('allowNotification')

    // Password
    const forgotPasswordEmail = useState('password_email')
    const newPassword = useState('password_newPassword')
    const newPasswordConfirmation = useState('password_newPasswordConfirmation')
    const passwordResponse = useState('passwordResponse', () => "")

    const passwordIsLoading = useState('passwordIsLoading', () => false)
    const loginIsLoading = useState('loginFormIsLoading', () => false)
    const registerIsLoading = useState('loginFormIsLoading', () => false)

    const passwordErrors = useState('passwordErrors', () => [])
    const loginErrors = useState('loginErrors', () => [])
    const registerErrors = useState('registerErrors', () => [])
    const headers = useRequestHeaders(['cookie'])

    const valueShipping = useState("valueShipping", () => 1);
    const guestForm = useState("guestForm", () => {
        return {
            email: "",
            password: "",
            address: {
                shipping: {
                    addressName: "",
                    identity: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    postalCode: "",
                    countryCode: "",
                    city: "",
                    district: "",
                    town: "",
                    address: "",
                    addressType: "individual",
                    companyName: "",
                    taxNumber: "",
                    taxOffice: "",
                    email : ""
                },
                invoice: {
                    addressName: "",
                    identity: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    postalCode: "",
                    countryCode: "",
                    city: "",
                    district: "",
                    town: "",
                    address: "",
                    addressType: "individual",
                    companyName: "",
                    taxNumber: "",
                    taxOffice: "",
                    email : ""
                },
            },
            allowAgreement: false,
            allowNotification: false,
        };
    });
    const guestFormIsLoading = useState("guestFormIsLoading", () => false);
    const guestAddressType = useState("guestAddressType", () => "individual");
    const guestAddressList = useState("guestAddressList", () => []);
    const guestErrors = useState("guestErrors", () => {});
    const sameWithShippingAddress = useState("sameWithShippingAddress", () => true);

    const billingAddress = useState("billingAddress");
    const shippingAddress = useState("shippingAddress");

    const {locale} = useI18n()

    /**
     * Toptan müşterisi mi? Normal müşteri mi?
     */
    const isWholesaleUser = computed(() => status?.value === 'authenticated' && data?.value?.customer_type === 1)

    /**
     * Müşteri tipini getiriyoruz. 0 : Normal - 1 : Toptan/Wholesale
     */
    const getUserType = computed(() => status?.value === 'authenticated' ? data?.value.customer_type : null)

    // Login
    const submitLogin = async ({email, password, isGuestLogin = false}) => {
        loginIsLoading.value = true;
        loginErrors.value = "";
        const appBasketId = basketId.value;
        const { error, url } = await signIn('credentials', { email, password, appBasketId, redirect: false, isGuestLogin : isGuestLogin, lang : locale.value })
        if (error) {
            loginErrors.value = isJson(error) ? JSON.parse(error) : error;
            loginIsLoading.value = false;
            // Do your custom error handling here
            // alert('You have made a terrible mistake while entering your credentials')
        } else {
            authModalIsVisible.value = false;
            loginIsLoading.value = false;

            // No error, continue with the sign in, e.g., by following the returned redirect:
            return navigateTo(url, { external: true })
        }
    }

    // Register
    const submitRegister = async (registerType = 0) => {
        registerErrors.value = [];
        registerIsLoading.value = true;
        showRetryButton.value = false;

        const data = await $fetch("/api/auth/register", {
            method: "post",
            body: {
                firstName: newFirstName.value,
                lastName: newLastName.value,
                email: newCustomerEmail.value,
                password: newCustomerPassword.value,
                gender: newCustomerGender.value,
                allowAgreement: allowAgreement.value,
                allowNotification: allowNotification.value,
                registerType : registerType ? 'guest' : 'full',
                phoneNumber : newCustomerPhone.value,
                verificationCode : verificationCode.value
            },
            onResponseError({ request, response, options, error }) {
                // Handle the response errors
                registerErrors.value = response._data.data.message;
                registerIsLoading.value = false;
            },
        });

        if(data?.status === 'pending_validation') {
            showVerificationInput.value = true;
            verificationType.value = data?.verificationType;
            startTimer()
        }

        if (data?.status === "success") {
            authModalIsVisible.value = false
            showVerificationInput.value = false;
            if (timer) clearInterval(timer)

            fbCompleteRegistration(newFirstName.value, newCustomerEmail.value)
            gCompleteRegistration()

            $showToast(data.message, data?.status)

            setTimeout(() => {
                // Eğer client tarafında bir problem yok ise hard redirect yapıyoruz ki sayfa baştan yüklensin
                submitLogin({ email : newCustomerEmail.value, password : newCustomerPassword.value })
            }, 2000)
        }

        if (data?.status === "error") {
            //showVerificationInput.value = false;
            registerErrors.value = data?.message;
        }

        registerIsLoading.value = false;

        return data;
    };

    const submitGuestRegister = async () => {
        guestErrors.value = [];
        guestFormIsLoading.value = true;
        showRetryButton.value = false;

        const data = await $fetch("/api/auth/register", {
            method: "post",
            body: {
                ...unref(guestForm),
                withAddress: 1,
                sameForShipping: valueShipping.value,
                verificationCode : verificationCode.value
            },
            onResponseError({ request, response, options, error }) {
                // Handle the response errors
                guestErrors.value = response._data.data.message;

                // Scroll to top
                if (typeof window !== 'undefined') {
                    window.scrollTo(0,0);
                }

                guestFormIsLoading.value = false;

                if (response?.status === 422) {
                    $showToast("Lütfen eksik bilgileri tamamlayınız", 'error');
                }
            },
        });

        if(data?.status === 'pending_validation') {
            showVerificationInput.value = true;
            verificationType.value = data?.verificationType;
            startTimer()
        }

        if (data?.status === "success") {
            showVerificationInput.value = false;
            if (timer) clearInterval(timer)

            fbCompleteRegistration(guestForm.value.address.shipping.firstName, guestForm.value.email)
            gCompleteRegistration()

            $showToast(data?.message, data?.status);

            if (!data?.isGuest) {
                await submitLogin({ email : guestForm.value.email, password : guestForm.value.password, lang : locale.value })
            } else {
                await submitLogin({ email : guestForm.value.email, password : data?.token, isGuestLogin : true, lang : locale.value })
            }

        } else if (data?.status === "error") {
            $showToast(data?.message, data?.status);
        }

        guestFormIsLoading.value = false;
    };

    const getUser = async () => {

        const data = await $fetch('/api/customer', {
            headers
        });

        user.value = data;

        return data;
    }

    /**
     * Şifre Sıfırlama İsteği Gönderir
     */
    const forgotPassword = async () => {

        passwordIsLoading.value = true
        passwordErrors.value = []
        passwordResponse.value = ""

        const data = await $fetch('/api/auth/password/forgot', {
            method: "POST",
            body : {
                email: forgotPasswordEmail.value
            },
            onResponseError({response}) {
                passwordIsLoading.value = false

                if (response._data.message && typeof response._data.message === 'string') {
                    $showToast(response._data?.message, response._data?.status)
                } else {
                    passwordErrors.value = response._data.message;
                }
            }
        })

        $showToast(data?.message, data?.status)
        passwordIsLoading.value = false
        passwordResponse.value = data?.message

        // bir süre sonra sıfırlıyoruz yoksa sayfada kalıyor.
        setTimeout(() => {
            passwordResponse.value = ""
        }, 5000)

    }

    /**
     * Şifre Değiştirir
     */
    const resetPassword = async ({token}) => {

        passwordIsLoading.value = true
        passwordErrors.value = []

        const data = await $fetch('/api/auth/password/reset', {
            method: "POST",
            body : {
                token: route.query.token,
                email: route.query.email,
                password : newPassword.value,
                password_confirmation : newPasswordConfirmation.value
            },
            onResponseError({response}) {
                if (typeof response._data?.message === 'object') {
                    passwordErrors.value = response._data.message;
                } else {
                    $showToast(response._data?.message, 'error')
                }
                passwordIsLoading.value = false
            }
        })

        $showToast(data.message, data?.status)

        passwordIsLoading.value = false

        setTimeout(() => {
            navigateTo('/login?reset=success')
        }, 3000)
    }

    return {
        valueShipping,
        isWholesaleUser,
        getUserType,
        user,
        userEmail,
        userPassword,
        rememberMe,
        loginIsLoading,
        registerIsLoading,
        loginErrors,
        registerErrors,
        newFirstName,
        newLastName,
        newCustomerEmail,
        newCustomerPassword,
        newCustomerGender,
        allowNotification,
        allowAgreement,
        authModalIsVisible,
        forgotPasswordEmail,
        newPassword,
        newPasswordConfirmation,
        passwordIsLoading,
        passwordErrors,
        passwordResponse,
        newCustomerPhone,

        billingAddress,
        shippingAddress,

        guestFormIsLoading,
        sameWithShippingAddress,
        guestForm,
        guestAddressList,
        guestErrors,
        guestAddressType,
        submitGuestRegister,
        submitLogin,
        submitRegister,
        getUser,
        forgotPassword,
        resetPassword
    }
}