<template>
    <button :disabled="disabled" class="btn" :class="[sizeClass, colorClass, formClass, outlineClass, 'focus:outline-none']" :type="inputType">
        <slot name="icon" v-if="!loading"></slot>
        <svg class="animate-spin mr-2" :class="[iconSizeClass, iconColorClass]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-else-if="loading">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <i class="mr-2" v-if="icon?.length>0" :class="icon"></i>
        <slot></slot>
        <i class="ml-2"  v-if="iconRight?.length>0" :class="iconRight"></i>
        <i class="fa-lg" v-if="iconButton?.length>0" :class="iconButton"></i>
    </button>
</template>
<script setup>
    const props = defineProps({
        size: String,
        color: String,
        icon: String,
        iconRight: String,
        iconButton: String,
        disabled: Boolean,
        loading: Boolean,
        form: String,
        outline: Boolean,
        inputType: {
            default : 'button'
        },
    })

    const iconSizeClass = computed(() => {
      if(props.size==='xl'){
        return 'size-6'
      } else if(props.size==='lg'){
        return 'size-4.5'
      } else if(props.size==='md'){
        return 'size-4'
      } else if(props.size==='sm'){
        return 'size-3.5'
      } else if(props.size==='xs'){
        return 'size-3'
      } else {
        return "size-5";
      }
    });

    const sizeClass = computed(() => {
        if(props.size==='xl'){
            return 'btn-xl'
        } else if(props.size==='lg'){
            return 'btn-lg'
        } else if(props.size==='md'){
            return 'btn-md'
        } else if(props.size==='sm'){
            return 'btn-sm'
        } else if(props.size==='xs'){
            return 'btn-xs'
        } else {
            return null;
        }
    });

    const colorClass = computed(() =>{
        if(props.color==='primary' && props.disabled){
            return 'btn-primary btn-primary-disabled'
        } else if(props.color==='primary'){
            return 'btn-primary'
        } else if(props.color==='secondary' && props.disabled){
            return 'btn-secondary btn-secondary-disabled'
        } else if(props.color==='secondary'){
            return 'btn-secondary'
        } else if(props.color==='danger' && props.disabled){
            return 'btn-danger btn-danger-disabled'
        } else if(props.color==='danger'){
            return 'btn-danger'
        } else if(props.color==='danger-100'){
            return 'btn-danger-100'
        } else if(props.color==='success' && props.disabled){
            return 'btn-success btn-success-disabled'
        } else if(props.color==='success'){
            return 'btn-success'
        } else if(props.color==='white' && props.disabled){
            return 'btn-white btn-white-disabled'
        } else if(props.color==='white'){
            return 'btn-white'
        } else if(props.color==='gray'){
            return 'btn-gray'
        } else {
            return props.color
        }
    });

    const iconColorClass = computed(() =>{
        if(inArray(props.color, ['primary', 'secondary', 'danger', 'success'])){
            return 'text-white'
        } else {
            return 'text-primary'
        }
    });

    const formClass = computed(() => {
        if(props.form==='square'){
            return 'btn-square'
        } else if(props.form==='rounded'){
            return 'btn-rounded'
        }
    })

    const outlineClass= computed(() => {
        if(props.outline){
            return 'btn-outline'
        } return null
    })
</script>
