<template>
  <div class="product-features-wrapper" v-if="product?.features.length">
    <template v-for="item in product.features">
      <div class="product-features-item" v-if="item.attributeItemName">
        <NuxtImg :src="item.attributeImage" height="24px" class="feature-img" />
        <span class="feature-name">{{ item.attributeName }} : </span>{{ item.attributeItemName }}
      </div>
    </template>
  </div>
</template>

<script setup>
const {
  product
} = await useProduct()
</script>