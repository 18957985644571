<script setup>

const storeConfig = useStoreConfig()
const {baseConfig} = storeToRefs(storeConfig)
const { t } = useI18n({
  useScope: 'local'
})

useHead({
  title: t('subscription-expired')
})

</script>

<template>
    <div class="container py-20 text-center flex flex-col items-center justify-center">
      <div class="flex flex-col items-center justify-center text-center py-4 px-4 space-y-10">
        <div class="font-medium text-primary">
          <p>
            {{ t('subscription-expired')}}
          </p>
        </div>
      </div>
    </div>
</template>

<i18n lang="json">
{
  "tr": {
    "subscription-expired" : "Bu web sitesi şu anda kullanılamamaktadır."
  },
  "en": {
    "subscription-expired" : "This website is unavailable now."
  }
}
</i18n>