export default defineNuxtPlugin((nuxtApp) => {
    const storeConfig = useStoreConfig()
    const {facebookPixelConfig} = storeToRefs(storeConfig)

    const isCookieAccepted = useState('isCookieAccepted')
      
    // Facebook Piksel aktif ise yüklüyoruz
    if (facebookPixelConfig.value.isActive && facebookPixelConfig.value.pixelId) {
        useHead({
            script: [
                {
                    children: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${facebookPixelConfig.value.pixelId}');
                        fbq('track', 'PageView');`
                }
            ],
            noscript: [
                {
                    src: `https://www.facebook.com/tr?id=${facebookPixelConfig.value.pixelId}&ev=PageView&noscript=1`,
                    width: 1,
                    height: 1,
                    style: "display:none"
                }
            ]
        })
    }
})