<template>
    <TransitionRoot appear :show="isShow" as="template">
        <div class="fixed inset-0 overflow-y-auto text-primary" :class="zIndexClass">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                <TransitionChild
                    as="template"
                    enter="duration-200 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div
                        class="fixed inset-0 bg-black/60 transition-opacity"/>
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <TransitionChild
                    as="template"
                    enter="duration-200 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                >
                    <OnClickOutside
                        class="inline-block text-left align-bottom bg-white rounded-lg transform transition-all sm:my-8 sm:align-middle w-full"
                        :class="widthClass" @trigger="closeWhenClickOutside">
                        <template v-if="isFull">
                            <slot name="imgHeader"/>
                            <div class="absolute top-0 right-0 pt-4 pr-4" v-if="!hideClose">
                                <FormButton @click="close" form="rounded" color="gray">
                                    <XMarkIcon class="md:h-4 md:w-4 h-3 w-3"/>
                                </FormButton>
                            </div>
                        </template>
                        <template v-else>
                            <!-- Default The Heading Section -->
                            <div v-if="!withoutHeader">
                                <div class="flex flex-row items-center justify-between p-4">
                                    <div class="flex flex-col items-start text-left font-medium">
                                        <template v-if="$slots.title">
                                            <slot name="title"></slot>
                                        </template>
                                        <template v-else>
                                            <div class="text-xl text-primary">{{ title }}
                                            </div>
                                            <div class="text-sm font-normal text-primary" v-if="description">
                                                {{ description }}
                                            </div>
                                        </template>
                                    </div>
                                    <FormButton @click="close" form="rounded" color="transparent text-primary dark:text-gray-600 hover:text-lightgray-600 p-1" v-if="!hideClose">
                                        <XMarkIcon class="md:h-4 md:w-4 h-3 w-3"/>
                                    </FormButton>
                                </div>
                            </div>
                        </template>
                        <!-- The Body Section of the Modal/Dialog -->
                        <div :class="bodyClass">
                            <slot/>
                        </div>
                        <hr v-if="footerSeparator" class="my-4 border-lightgray-100 dark:border-darkgray-500"/>
                        <div class="px-4 pb-4 flex justify-end" v-if="$slots.footer">
                            <slot name="footer"/>
                        </div>
                    </OnClickOutside>
                </TransitionChild>
            </div>
        </div>
    </TransitionRoot>
</template>

<script setup>
import {TransitionChild, TransitionRoot} from "@headlessui/vue";
import {XMarkIcon} from '@heroicons/vue/24/solid';
import {OnClickOutside} from '@vueuse/components';

const props = defineProps({
    title: String,
    description: String,
    widthClass: {
        type: String,
        default: 'max-w-lg'
    },
    hideClose: Boolean,
    isFull: Boolean,
    modelValue: Boolean,
    footerSeparator: {
        type: Boolean,
        default: false
    },
    withoutHeader: {
        type: Boolean,
        default: false
    },
    closeOnClickOutside: {
        type: Boolean,
        default: true
    },
    bodyClass: {
        type: String,
        default: 'my-4 px-4'
    },
    zIndexClass : {
      type : String,
      default : 'z-400'
    }
})

const emit = defineEmits(['update:modelValue', 'close'])

const isShow = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

function close() {
    if(!props.hideClose) {
      emit('close', true)
      isShow.value = false;
    }
}

function closeWhenClickOutside() {
  if (!props.closeOnClickOutside) return;
  close()
}

// ESC tuşu dinleyicisini ekleme
const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    close()
  }
}

// Modal açıldığı zaman body'nin scroll olması sorununu çözer
watch(() => props.modelValue, (val) => {
  if (typeof document !== 'undefined') {
    if (val) {
      document.getElementsByTagName("body")[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName("body")[0].style.overflow = 'auto'
    }
  }
})

// Component yüklendiğinde ESC dinleyicisini ekle
onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

// Component kaldırıldığında ESC dinleyicisini kaldır
onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})

</script>
