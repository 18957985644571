<script setup>

const {product, pending} = await useProduct()
const { data: reviews } = useNuxtData(`productReviews::${product?.value?.productId}`)

</script>

<template>
    <div class="text-primary">
          <div v-if="reviews?.results?.length > 0" class="space-y-2">
              <div v-for="(result,idx) in reviews.results" class="flex flex-col" :key="`r-${idx}`">
                <div class="flex flex-row py-4 text-sm items-center text-gray">
                  {{result.name}} - {{result.date}}
                </div>
                <div class="flex text-center p-4 bg-gray-100 rounded-lg">
                    <FormRatingStar :grade="result.rating" editable="true"/>
                    <span class="text-left ml-4">{{result.comment}}</span>
                </div>
              </div>
          </div>

          <span v-else>{{ $t('comps.review.no-review') }}</span>
    </div>
</template>