export default defineNuxtPlugin((nuxtApp) => {
    const storeConfig = useStoreConfig()
    const {zendeskConfig} = storeToRefs(storeConfig)
    const isCookieAccepted = useState('isCookieAccepted')

    // Zendesk plugin aktif ise yüklüyoruz
    if (zendeskConfig.value.isActive && zendeskConfig.value.apiKey) {
        useHead({
            script: [
                {src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskConfig.value.apiKey}`, id: 'ze-snippet'}
            ]
        })
    }
})