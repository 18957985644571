<template>
  <form @submit.prevent="submitLogin( { email : userEmail, password : userPassword } )" class="space-y-3">
    <FormInput
        :label="$t('auth.email')"
        input-type="email"
        name="email"
        :error="viewResponseError(loginErrors, 'email')"
        required
        v-model="userEmail"
    />

    <FormInput
        :label="$t('auth.password')"
        input-type="password"
        name="password"
        :error="viewResponseError(loginErrors, 'password')"
        required
        v-model="userPassword"
    />

    <div class="flex items-center justify-end">
      <NuxtLinkLocale
          href="/password/forgot"
          class="font-medium text-base text-indigo-600 hover:text-indigo-500"
      >
        {{$t('auth.forgot-password')}}
      </NuxtLinkLocale>
    </div>

    <div class="w-full">
      <FormButton
          size="md"
          color="primary"
          class="w-full uppercase"
          input-type="submit"
          :loading="loginIsLoading"
      >
        {{$t('auth.sign-in')}}
      </FormButton>

      <div class="text-red text-sm font-medium mt-3" v-if="typeof loginErrors === 'string'" v-text="loginErrors"></div>

    </div>
  </form>
</template>

<script setup>
import useCustomer from "~/composables/useCustomer";

const userEmail = ref();
const userPassword = ref();
const { status, data } = await useAuth()
const { authModalIsVisible, submitLogin, loginErrors, loginIsLoading } = await useCustomer()

</script>