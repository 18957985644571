import collect from "collect.js";
import useFacebookPixel from "~/composables/useFacebookPixel";
import {formatPrice} from "~/composables/useHelper";

const paymentAmount = ref(0)

export default async () => {
    const {$showToast} = useNuxtApp()

    const storeConfig = useStoreConfig()
    const {currency, updateLineInBasket} = storeToRefs(storeConfig)

    const {fbAddToCart} = useFacebookPixel()
    const {gAddToCart, gInitiateCheckout} = useGoogleEvents()

    const appCountry = useCookie('appCountry')
    const appCity = useCookie('appCity')
    const appDistrict = useCookie('appDistrict')
    const appTown = useCookie('appTown')

    const isBasketContentVisible = useState('isBasketContentVisible', () => false);
    const addCartLoading = useState('addCartLoading', () => false);
    const oldBasketTotal = useState('oldBasketTotal', () => 0)
    const basketTotal = useState('basketTotal', () => 0)
    const basketItems = useState('basketItems', () => [])
    const basketCount = useState('basketCount', () => 0)

    const basketLoading = ref(false)
    const basketCurrency = useState('basketCurrency', () => currency.value)
    const basketCurrencyRate = useState('basketCurrencyRate', () => 1)
    const paymentFee = useState('paymentFee', () => 0)
    const paymentType = useState('paymentType', () => 1)
    const creditCardPrice = useState('creditCardPrice', () => 0)
    const paymentOptions = useState('paymentOptions', () => [])
    const deliveryOptions = useState('deliveryOptions', () => [])
    const selectedShippingOption = useState('selectedShippingOption', () => {});
    const offerStatus = useState('offerStatus')
    const customerNote = useState('customerNote')
    const deliveryNote = useState('deliveryNote')
    const vatIncludedBasketTotal = useState('vatIncludedBasketTotal', () => 0)
    const vatIncludedOldBasketTotal = useState('vatIncludedOldBasketTotal', () => 0)
    const saveAmount = useState('saveAmount', () => 0)
    const currencyConvertedLines = useState('currencyConvertedLines', () => [])
    const localCheckoutTotal = useState('localCheckoutTotal', () => 0)

    // Ücretsiz kargo limiti
    const freeShippingLimit = useState('freeShippingLimit', () => 0);

    // Ücretsiz kargo için kalan limit
    const freeShippingRemainAmount = useState('freeShippingRemainAmount', () => 0);

    const cardHolder = useState("cardHolder", () => "");
    const cardNumber = useState("cardNumber", () => "");
    const cardExpireYear = useState("cardExpireYear", () => "");
    const cardExpireMonth = useState("cardExpireMonth", () => "");
    const cardCVV = useState("cardCVV", () => "");
    const maskType = useState("maskType", () => "9999 9999 9999 9999");

    const installmentLoading = useState('installmentLoading', () => false)
    const installments = useState('installments', () => [])
    const paymentErrors = useState('paymentErrors', () => [])
    const selectedBankAccount = useState('selectedBankAccount', () => {})
    const bankAccounts = useState('bankAccounts', () => [])
    const bankAccountsLoading = useState('bankAccountsLoading', () => false)
    const selectedInstallment = useState('selectedInstallment', () => {
        return {
            installmentNumber : 1,
            installmentPrice : 0,
            totalPrice : 0,
        }
    })
    const installmentFee = useState('installmentFee', () => 0)
    const checkoutLoading = useState('checkoutLoading', () => false)

    // Promo & Coupon Codes
    const promoCode = useState('promoCode', () => "")
    const promoCodeApplied = useState('promoCodeApplied', () => false)
    const promoCodeMessage = useState('promoCodeMessage', () => "")
    const promoCodeHasError = useState('promoCodeHasError', () => null)
    const loadingPromoCode = useState('loadingPromoCode', () => false)
    const promoModalVisible = useState('promoModalVisible', () => false)

    // Contracts
    const contractData = useState('contractData')
    const isContractLoading = useState('isContractLoading', () => false)
    const isContractAccepted = useState('isContractAccepted', () => false)
    const isShowContractModal = useState('isShowContractModal', () => false)
    const agreementType = useState('agreementType')

    const billingAddress = useState('billingAddress')
    const shippingAddress = useState('shippingAddress')

    const getInstallment = async (price) => {

        installments.value = []

        installmentLoading.value = true;

        // Set Installment List
        installments.value = await $fetch('/api/checkout/installments', {
            query: {
                binNumber: binNumber.value,
                price: price ? price : checkoutTotal()
            }
        });

        if (installments.value?.length) {
            selectedInstallment.value = installments.value[0]
        }

        installmentLoading.value = false;
    };

    const submitPaymentRequest = async () => {
        checkoutLoading.value = true;
        paymentErrors.value = []

        const {data, error} = await useFetch('/api/checkout', {
            method : 'post',
            body : {
                billingAddressId: billingAddress.value,
                shippingAddressId: shippingAddress.value,
                paymentMethod: selectedPaymentGateway.value.methodType,
                paymentGateway: selectedPaymentGateway.value.providerCode,
                deliveryOption: selectedShippingOption.value,
                currency : currency.value,
                creditCardForm: {
                    cardName : cardHolder.value,
                    cardNumber : cardNumber.value,
                    cardMonth : cardExpireMonth.value,
                    cardYear : cardExpireYear.value,
                    cardCvv : cardCVV.value,
                    registerCard : 0,
                    installment: selectedInstallment.value?.installmentNumber,
                    cardToken : "",
                },
                customerNote : customerNote.value,
                deliveryNote : deliveryNote.value
            },
            onResponse() {
                // Google etkinliği
                gInitiateCheckout(currency.value, basketTotal.value, googleCheckoutItems.value)
            }
        })

        if (error.value) {
            if (typeof error.value.data.message === 'string') {
                $showToast(error.value.data.message, 'error', 9000)
            } else {
                $showToast('Lütfen eksik bilgileri tamamlayınız.', 'error', 9000)
            }
            paymentErrors.value = error.value.data.message;
            checkoutLoading.value = false
            return;
        }

        if (data.value?.htmlContent) {
            document.open();
            document.write(data.value?.htmlContent);
            document.close();
        } else {
            if (data.value?.status === 'success') {
                navigateTo({ path : '/checkout/summary', query : { orderId : data.value.orderId }})
            } else if(data.value?.status === 'error') {
                navigateTo({ path : '/checkout/summary', query : { errorMessage : data.value?.message }})
            } else {
                $showToast(data.value?.message, 'error');
            }
        }

        checkoutLoading.value = false
    };

    const submitDirectPaymentRequest = async () => {
        checkoutLoading.value = true;
        paymentErrors.value = []

        const {data, error} = await useFetch('/api/checkout/payment', {
            method : 'post',
            body : {
                billingAddressId: billingAddress.value,
                shippingAddressId: shippingAddress.value,
                currency : currency.value,
                creditCardForm: {
                    cardName : cardHolder.value,
                    cardNumber : cardNumber.value,
                    cardMonth : cardExpireMonth.value,
                    cardYear : cardExpireYear.value,
                    cardCvv : cardCVV.value,
                    registerCard : 0,
                    installment: selectedInstallment.value?.installmentNumber,
                    cardToken : "",
                },
                paymentAmount : paymentAmount.value,
                paymentType : paymentType.value
            }
        })

        if (error.value) {
            if (typeof error.value.data.message === 'string') {
                $showToast(error.value.data.message, 'error', 9000)
            } else {
                $showToast('Lütfen eksik bilgileri tamamlayınız.', 'error', 9000)
            }
            paymentErrors.value = error.value.data.message;
            checkoutLoading.value = false
            return;
        }

        if (data.value?.htmlContent) {
            document.open();
            document.write(data.value?.htmlContent);
            document.close();
        } else {
            if (data.value?.status === 'success') {
                navigateTo({ path : '/payment/summary', query : { orderId : data.value.orderId }})
            } else if(data.value?.status === 'error') {
                navigateTo({ path : '/payment/summary', query : { errorMessage : data.value?.message }})
            } else {
                $showToast(data.value?.message, 'error');
            }
        }

        checkoutLoading.value = false
    };

    const getDeliveryOptions = async () => {
        const {data, error} = await useFetch('/api/checkout/delivery-options', {
            params : {
                countryCode : appCountry.value,
                cityId : appCity.value,
                districtId : appDistrict.value,
                townId : appTown.value
            },
            key : 'deliveryOptions',
            server : false
        })

        deliveryOptions.value = data;

        return data;
    }

    const getPaymentOptions = async (providerCode) => {
        const {data, error} = await useFetch('/api/checkout/payment-gateways', {
            key : 'paymentOptions::'+providerCode,
            query: {
                shipmentCompanyCode: providerCode,
            },
            server : false
        })


        paymentOptions.value = data.value;

        return data.value;
    }

    const getBankAccounts = async () => {
        const {data, error} = await useLazyFetch('/api/checkout/bank-accounts', {
            server : false
        })

        bankAccounts.value = data;

        return data;
    }

    const scrollToTop = () => {
        if (typeof window === 'undefined') {
            return;
        }
        window.scrollTo(0, 0);
    }

    const addToBasket = async (variantId, qty, basketCurrency = null, warehouseId = null, isUpdate = null, scrollToTopIsActive = true, disableBasketVisible = false, price = null, productTitle = null, attributes = []) => {

        addCartLoading.value = variantId;

        const data = await $fetch("/api/basket", {
            method: 'POST',
            body: {
                variantId,
                qty,
                warehouseId,
                isUpdate : updateLineInBasket.value ? updateLineInBasket.value : isUpdate,
                //currency : basketCurrency ? basketCurrency : currency.value,
                rawCurrency: basketCurrency ? basketCurrency : null,
                attributes
            },
            // async onResponse({response}) {
            //     content.value = response._data?.payload
            // }
            onResponseError({response}) {
                $showToast(response._data?.data, 'error')
                addCartLoading.value = false;
            }
        })

        if (data?.status === 'success') {
            fbAddToCart([variantId], productTitle, price, basketCurrency ? basketCurrency : currency.value)
            gAddToCart(variantId, productTitle, price, basketCurrency ? basketCurrency : currency.value)

            await refreshNuxtData("userBasket")

            if (!disableBasketVisible) {
                isBasketContentVisible.value = true;
                if (scrollToTopIsActive) scrollToTop()
            }
        } else if(data?.status === 'error') {
            $showToast(data?.message, 'error')
        }

        addCartLoading.value = false;

        return data
    };

    const updateBasket = async (variantId, lineId, qty, basketCurrency = null) => {
        addCartLoading.value = false;
        const data = await $fetch("/api/basket", {
            method: 'POST',
            body: {
                variantId,
                lineId,
                qty,
                isUpdate : true,
                currency : basketCurrency ? basketCurrency : currency.value
            },
            onResponseError({response}) {
                $showToast(response._data?.data, 'error')
                addCartLoading.value = false;
            }
        })

        if (data?.status === 'success') {
            await refreshNuxtData("userBasket")
        } else if(data?.status === 'error') {
            $showToast(data?.message, 'error')
        }

        addCartLoading.value = false;
    }

    const deleteBasket = async (lineId) => {
        const data = await $fetch("/api/basket", {
            method: 'DELETE',
            body: {
                lineId
            }
        })


        if (data?.status === 'success') {
            refreshNuxtData("userBasket")
        }

        return data;
    };

    const toggleBasketContent = () => {
        isBasketContentVisible.value = !isBasketContentVisible.value
    }

    const isCartAdded = (variantId) => {
        return collect(basketItems.value).where('variant_id', variantId.toString()).sum("qty")
    }

    const removePromoCode = async () => {
        loadingPromoCode.value = true

        const {data, error} = await useFetch('/api/checkout/coupon', {
            method : 'DELETE',
            query : {
                promoCode: promoCode.value
            },
            server : false
        })

        if (data.value.status === "success") {
            $showToast(data.value.message, 'success');
            refreshNuxtData("userBasket")
        } else {
            $showToast(data.value.message, 'error');
        }

        loadingPromoCode.value = false
    }

    const addPromoCode = async () => {
        loadingPromoCode.value = true
        promoCodeHasError.value = false;

        const {data, error} = await useFetch('/api/checkout/coupon', {
            method : 'post',
            body : {
                promoCode: promoCode.value
            },
            server : false
        })

        if (error.value) {
            $showToast(error.value.data.message, 'error')
            loadingPromoCode.value = false
            promoCodeHasError.value = error.value.data.message;
            return;
        }

        if (data.value?.status === "success") {
            $showToast(data.value.message, 'success');
            refreshNuxtData("userBasket")
        } else {
            $showToast(data?.message, 'error');
        }

        loadingPromoCode.value = false
    }

    const openContract = async (type) => {
        agreementType.value = type;
        isContractAccepted.value = false
        isShowContractModal.value = true
    }

    const closeContract = async (type) => {
        agreementType.value = "";
        isContractAccepted.value = false
        isShowContractModal.value = false
    }

    const acceptContract = async () => {
        isContractAccepted.value = true
        isShowContractModal.value = false
    }

    const getContract = async () => {
        isContractLoading.value = true

        const data = await $fetch('/api/checkout/contract', {
            query : {
                agreementType : agreementType.value,
                addressId : shippingAddress.value
            },
            server : false
        })

        contractData.value = data

        isContractLoading.value = false
    }

    const getAttributeName = (product) => {
        if (!product?.attributes || product?.attributes.length === 0) return;

        const unitName = collect(product.attributes).where('attribute_type', 'unit_name').pluck('attribute_value').first();

        return unitName ? unitName : t('listing.piece');
    }

    const getAttributeValue = (product, attributeType) => {
        if (!product?.attributes || product?.attributes.length === 0) return;
        return collect(product.attributes).where('attribute_type', attributeType).pluck('attribute_value').first();
    }

    // Kredi kartının ilk 6 hanesi
    const binNumber = computed(() => cardNumber.value.replace(/\s/g, '').substring(0, 6))

    const minCardYear = computed(() => new Date().getFullYear())
    const minCardMonth =  computed(() => {
        if (cardExpireYear.value === minCardYear.value) return new Date().getMonth() + 1
        return 1
    })

    // Kart Tipi - VISA, AMEX vb.
    const cardType = computed(() => {
        let number = cardNumber.value;

        if (!number) return null

        let re = new RegExp("^4");
        if (number.match(re) != null) return "VISA";

        re = new RegExp("^(34|37)");
        if (number.match(re) != null) return "AMEX";

        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "MASTER_CARD";

        re = new RegExp("^6011");
        if (number.match(re) != null) return "DISCOVER";

        re = new RegExp('^9792')
        if (number.match(re) != null) return 'TROY'

        return null; // default type
    })

    // Aktif sepet ürünleri
    const activeBasketItems = computed(() => basketItems.value.filter((i) => i.item_type === 'product' && i.status === 1));

    // Pasif sepet ürünleri (stoğu biten  vb.)
    const passiveBasketItems = computed(() => basketItems.value.filter((i) => i.item_type === 'product' && i.status === 0));

    const googleCheckoutItems = computed(() => collect(activeBasketItems.value).map((i) => {
        return {
            item_id : i.variant_id,
            item_name : i.title
        }
    }).toArray());

    // Ödeme toplamı
    const checkoutTotal = (getOldPrice = false, withShipping = true) => {
        const basketPrice = getOldPrice ? vatIncludedOldBasketTotal.value : vatIncludedBasketTotal.value;
        const shippingPrice = withShipping ? parseFloat(shippingFee.value) : 0;

        if (selectedPaymentGateway.value?.methodType !== 'creditCard') {
            paymentFee.value = selectedPaymentGateway.value?.options?.serviceFee;

            if (paymentFee.value) {
                return basketPrice + parseFloat(paymentFee.value) + shippingPrice;
            } else {
                return basketPrice + shippingPrice;
            }
        } else {
            paymentFee.value = null;

            if (creditCardPrice.value) {
                return creditCardPrice.value;
            } else {
                return basketPrice + shippingPrice + installmentFee.value;
            }
        }
    }

    const priceDiffPercentForCart = computed(() => customRound(relDiff(vatIncludedOldBasketTotal.value, vatIncludedBasketTotal.value)))

    const oldCheckoutTotalText = computed(() => formatPrice(checkoutTotal(true), unref(basketCurrency)))
    const checkoutTotalText = computed(() => formatPrice(checkoutTotal(), unref(basketCurrency)))
    const isOldTotalExists = computed(() => oldCheckoutTotalText.value !== checkoutTotalText.value)

    // Sepet toplamı
    // const basketTotal = await computed(() => collect(unref(activeBasketItems)).sum('totalPrice'))

    const oldBasketTotalText = computed(() => formatPrice(unref(oldBasketTotal), unref(basketCurrency)))

    const basketTotalText = computed(() => formatPrice(unref(basketTotal), unref(basketCurrency)))

    const vatTotal = computed(() => unref(vatIncludedBasketTotal) - parseFloat(unref(basketTotal)))

    // Kargo satırı
    const shippingItem = computed(() => basketItems.value.filter((i) => i.item_type === 'shippingFee'));

    // Kargo ücreti
    const shippingFee = computed(() => collect(shippingItem.value).sum('total_price'));

    const freeShippingLimitPercent = computed(() => (freeShippingLimit.value - freeShippingRemainAmount.value) * 100 / freeShippingLimit.value);

    // const paymentFee = await computed(() => collect(basketItems.value).sum('price'));

    const selectedPaymentGateway = useState('selectedPaymentGateway', () => {});

    return {
        updateBasket,
        addToBasket,
        deleteBasket,
        toggleBasketContent,
        getDeliveryOptions,
        getPaymentOptions,
        addCartLoading,
        basketItems,
        basketCount,
        basketTotal,
        basketTotalText,
        basketLoading,
        checkoutTotalText,
        shippingFee,
        basketCurrency,
        activeBasketItems,
        passiveBasketItems,
        isBasketContentVisible,
        deliveryOptions,
        paymentOptions,
        selectedShippingOption,
        offerStatus,
        selectedPaymentGateway,
        getInstallment,
        submitPaymentRequest,
        submitDirectPaymentRequest,
        checkoutLoading,
        installments,
        installmentLoading,
        selectedInstallment,
        binNumber,
        cardNumber,
        cardType,
        cardHolder,
        cardExpireYear,
        cardExpireMonth,
        cardCVV,
        maskType,
        paymentErrors,
        minCardYear,
        minCardMonth,
        getBankAccounts,
        bankAccounts,
        bankAccountsLoading,
        selectedBankAccount,
        agreementType,
        isContractAccepted,
        isShowContractModal,
        openContract,
        acceptContract,
        closeContract,
        getContract,
        promoModalVisible,
        contractData,
        isContractLoading,
        removePromoCode,
        addPromoCode,
        promoCodeHasError,
        promoCode,
        loadingPromoCode,
        promoCodeApplied,
        promoCodeMessage,
        freeShippingLimit,
        freeShippingRemainAmount,
        freeShippingLimitPercent,
        paymentAmount,
        googleCheckoutItems,
        customerNote,
        deliveryNote,
        oldBasketTotal,
        oldBasketTotalText,
        oldCheckoutTotalText,
        priceDiffPercentForCart,
        checkoutTotal,
        isOldTotalExists,
        vatIncludedBasketTotal,
        vatIncludedOldBasketTotal,
        vatTotal,
        saveAmount,
        currencyConvertedLines,
        localCheckoutTotal,
        basketCurrencyRate,
        installmentFee,
        isCartAdded,
        getAttributeName,
        getAttributeValue
    }
}
